import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';
import { default as BaseAxios, AxiosError } from 'axios';

export const instance = BaseAxios.create({
	baseURL:
		process.env['NODE_ENV'] === 'development'
			? `http://localhost:${process.env['NX_PORT']}`
			: process.env['NX_BACKEND_ENDPOINT'],
});

instance.defaults.withCredentials = true;

// instance.interceptors.response.use(
// 	(response) => {
// 		// Any status code that lie within the range of 2xx cause this function to trigger
// 		// Do something with response data
// 		return response;
// 	},
// 	(error) => {
// 		// Any status codes that falls outside the range of 2xx cause this function to trigger
// 		// Do something with response error

// 		// Dismiss all existing toasts.
// 		toast.dismiss();

// 		if (BaseAxios.isAxiosError(error)) {
// 			console.log(error);
// 			let errorMessage = 'Request error | ';

// 			if (error.response) {
// 				// The request was made and the server responded with a status code that falls out of the range of 2xx!
// 				const {
// 					status,
// 					data: { message },
// 				} = error.response as AxiosResponse;

// 				console.log(status);

// 				if (status === 401)
// 					errorMessage +=
// 						'Invalid token: Please log out and log back in.';
// 				else if (status === 403)
// 					errorMessage +=
// 						'Unauthorized access: You do not have the proper permissions to perform this action.';
// 				else if (status === 504)
// 					errorMessage +=
// 						'Server offline: Please contact the support team.';
// 				else errorMessage += message;
// 			} else if (error.request)
// 				// The request was made but no response was received!
// 				errorMessage += `Server error: Missing response. Error: ${error.message}`;
// 			else errorMessage += error.message;

// 			toast.error(errorMessage);
// 		} else {
// 			console.log(error);

// 			toast.error('Non-request error | Something went wrong! Try again!');
// 		}
// 	}
// );

export const handleRequestError = (
	err: unknown,
	toastId: string | undefined
) => {
	const toastOptions = { id: toastId };
	const error = err as Error | AxiosError;

	if (BaseAxios.isAxiosError(error)) {
		console.log(error);
		let errorMessage = 'Request error | ';

		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx!
			const {
				status,
				data: { message },
			} = error.response as AxiosResponse;

			console.log(status);

			if (status === 401)
				errorMessage +=
					'Invalid token: Please log out and log back in.';
			else if (status === 403)
				errorMessage +=
					'Unauthorized access: You do not have the proper permissions to perform this action.';
			else if (status === 504)
				errorMessage +=
					'Server offline: Please contact the support team.';
			else errorMessage += message;
		} else if (error.request)
			// The request was made but no response was received!
			errorMessage += `Server error: Missing response. Error: ${error.message}`;
		else errorMessage += error.message;

		toast.error(errorMessage, toastOptions);
	} else {
		console.log(error);

		toast.error(
			'Non-request error | Something went wrong! Try again!',
			toastOptions
		);
	}
};

export const handleTokenError = (err: unknown, toastId: string | undefined) => {
	const error = err as Error;
	const toastOptions = { id: toastId };

	console.log(error);
	toast.error('Token error: Try again!', toastOptions);
};
