import shared from './shared.json';
import v4_1 from './v4-1/v4-1.json';

export const BLADDERCARE_V4_1 = v4_1;
export const BLADDERCARE_VERSIONS = [
	{
		label: '4.1',
		value: '4.1',
	},
];

export const BLADDERCARE: { [key: string]: any } = {
	shared: shared,
	'4.1': BLADDERCARE_V4_1,
};
