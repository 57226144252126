import { ReactNode } from 'react';

import { Date } from '@pangea-lis-apps/utils';

export interface DataCellProps {
	className?: string;
	children: string | Date | undefined | null | ReactNode;
}

export function DataCell(props: DataCellProps) {
	return (
		<td
			className={`px-4 py-2 text-sm text-gray-500 w-full ${props.className}`}
		>
			{(() => {
				if (
					props.children === undefined ||
					props.children === null ||
					props.children === ''
				)
					return <p className="text-gray-300">N/A</p>;
				else if (
					typeof props.children === 'object' &&
					'$date' in props.children
				)
					return props.children['$date'];

				return props.children;
			})()}
		</td>
	);
}

export default DataCell;
