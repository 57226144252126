import { Dispatch, SetStateAction } from 'react';

export interface ID {
	$oid: string;
}

export interface Date {
	$date: string;
}

export interface User {
	id: string;
	email: string;
	last_name: string;
	first_name: string;
}

export interface ActionMetadata {
	by: User;
	date: Date;
}

export interface Metadata {
	created_by: User;
	date_created: Date;
	date_modified: Date;
}

export interface Query {
	[key: string]: string | number | boolean | string[];
}

export interface QueryState {
	query: Query;
	setQuery: Dispatch<SetStateAction<Query>>;
}

export interface Pagination {
	page_number: number;
	entries_per_page: 15;
	total_entries: number;
}
export interface PaginationState {
	pagination: Pagination;
	setPagination: Dispatch<SetStateAction<Pagination>>;
}

export const initialPaginationValues: Pagination = {
	page_number: 1,
	total_entries: 0,
	entries_per_page: 15,
};

export interface Option {
	label: string;
	value: string;
	disabled?: boolean;
	disables_all?: boolean;
}

export interface ModalState {
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

export interface Permission {
	id: string;
	name: string;
	description: string;
}

export interface Attribute {
	value: string;
	label: string;
	property: string;
	selectLabel?: string;
	fileTypeAccept?: string;
	selectOptions?: Option[] | undefined;
	type:
		| 'text'
		| 'email'
		| 'select'
		| 'date'
		| 'image'
		| 'multiple_select'
		| 'file'
		| 'textarea';
	validateFn?: (input: any) => {'status':boolean, 'errMessage': string}
}

export const initialAttributeValue: Attribute = {
	value: '',
	label: '',
	type: 'text',
	property: '',
	selectOptions: undefined,
	selectLabel: '',
};

export interface ProgressStep {
	id: string;
	step: string;
	name: string;
	href?: string;
	disabled?: boolean;
	status: 'complete' | 'current' | 'upcoming';
}
