import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
	Container,
	ContentSection,
	ContentWrapper,
	PageTabNavigation,
} from '@pangea-lis-apps/ui';

import PatientDetails from './details';
import PatientReports from './reports';

const tabs = [
	{ label: 'Details', value: 'details', show: true },
	{ label: 'Reports', value: 'reports', show: true },
];

/* eslint-disable-next-line */
export interface ViewPatientProps {}

export default function ViewPatient(props: ViewPatientProps) {
	const [searchParams] = useSearchParams();
	const tab = searchParams.get('tab');

	const [currentTab, setCurrentTab] = useState(tab ? tab : 'details');

	return (
		<Container>
			<ContentWrapper Icon="UserIcon" heading="Patient Information">
				<ContentSection>
					<PageTabNavigation
						tabs={tabs}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
				</ContentSection>
				{currentTab === 'details' && <PatientDetails />}
				{currentTab === 'reports' && <PatientReports />}
			</ContentWrapper>
		</Container>
	);
}
