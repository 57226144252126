import { useLocation } from 'react-router-dom';

import { Option } from './common-types';

export const isBoolean = (value: any) => {
	return value === false || value === true;
};

export const getRoleFromPathname = () => {
	// e.g., /accessioner/view/customers
	const { pathname } = useLocation();

	return pathname.split('/')[1];
};

export const getLabelFromValue = (value: string, options: Option[]) => {
	const formattedValue = value
		? value.trim().toLowerCase().replace(/\s/g, '_')
		: value;

	const option = options.filter(
		(option) => option.value === formattedValue || option.value === value
	);

	return option.length === 1 ? option[0].label : value;
};
