import v1_0 from './v1-0/v1-0.json';
import shared from './shared.json';

export const FIT_v1_0 = v1_0;
export const FIT_shared = shared;
export const FIT_VERSIONS = [
	{
		label: '1.0',
		value: '1.0',
	},
];

export const FIT: { [key: string]: any } = {
	'1.0': FIT_v1_0,
	shared: FIT_shared,
};
