export function LoadingPage() {
	return (
		<div className="w-full h-screen flex items-center justify-center">
			<div className="flex items-center justify-center rounded-full w-48 h-48 shadow-sm">
				<div className="animate-spin rounded-full border-t-4 border-blue-400 border-solid w-full h-full"></div>
				<img
					alt="Pangea Lab logo"
					className="w-20 scale-150 absolute"
					src="https://bentley-internal-test-submission-system-public.s3.amazonaws.com/pangea-lab-logo.png"
				/>
			</div>
		</div>
	);
}

export default LoadingPage;
