import samples from './samples.json';
import { Option } from '../../common-types';

export const SAMPLES = samples;

const removeDuplicatesByLabel = (array: Option[]) => {
	const map = new Map();

	array.forEach((item: Option) => {
		if (!map.has(item.label)) {
			map.set(item.label, item);
		}
	});

	return Array.from(map.values());
};

export const getSampleStatuses = (
	removeDuplicates?: boolean,
	optionsToRemove?: string[]
) => {
	const env = process.env['NX_PORTAL_TYPE']?.toLowerCase();
	let sampleStatuses = samples.statuses[env as 'associate' | 'customer'];

	if (removeDuplicates) {
		sampleStatuses = removeDuplicatesByLabel(sampleStatuses);
	}

	if (optionsToRemove) {
		sampleStatuses = sampleStatuses.filter(
			(option: Option) => !optionsToRemove.includes(option.value)
		);
	}

	return sampleStatuses;
};
