import {
	Dispatch,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import { Tab, classNames } from '@pangea-lis-apps/utils';

export interface PageTabNavigationProps {
	tabs: Tab[];
	currentTab: string;
	setCurrentTab: Dispatch<SetStateAction<string>>;
}

export function PageTabNavigation(props: PageTabNavigationProps) {
	const handleChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name) setCurrentTab(target.value);
	};

	const setCurrentTab = (tab: string) => {
		props.setCurrentTab(tab);
	};

	return (
		<div>
			<div className="sm:hidden">
				<label htmlFor="tabs" className="sr-only">
					Select a tab
				</label>
				<select
					id="tabs"
					name="tabs"
					onChange={handleChange}
					className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
				>
					{props.tabs.map((tab) => (
						<option key={tab.value}>{tab.label}</option>
					))}
				</select>
			</div>
			<div className="hidden sm:block">
				<div className="border-b border-gray-200">
					<nav className="-mb-px flex space-x-8" aria-label="Tabs">
						{props.tabs.map((tab, index) => (
							<button
								key={index}
								onClick={() => setCurrentTab(tab.value)}
								className={classNames(
									tab.value === props.currentTab
										? 'border-blue-500 text-blue-600'
										: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
									tab.show ? 'block' : 'hidden',
									'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
								)}
								aria-current={
									tab.value === props.currentTab
										? 'page'
										: undefined
								}
							>
								{tab.label}
							</button>
						))}
					</nav>
				</div>
			</div>
		</div>
	);
}

export default PageTabNavigation;
