import { EmptyBox } from '@pangea-lis-apps/ui';
import { IconName } from '@pangea-lis-apps/utils';

interface EmptyTableProps {
	Icon: IconName;
	colSpan?: number;
	heading: string;
	description?: string;
}

export function EmptyTable(props: EmptyTableProps) {
	return (
		<tr>
			<td colSpan={100} className="p-4">
				<EmptyBox
					Icon={props.Icon}
					heading={props.heading}
					description={props.description}
				/>
			</td>
		</tr>
	);
}
