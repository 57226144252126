import { ReactNode, ChangeEventHandler } from 'react';

import { classNames, Option } from '@pangea-lis-apps/utils';

import { Label } from '../label/label';

export interface SelectFieldProps {
	name: string;
	label?: string;
	value?: string;
	options: Option[];
	disabled?: boolean;
	required?: boolean;
	fieldAction?: ReactNode;
	showRequiredAsterisk?: boolean;
	handleSelect: ChangeEventHandler<HTMLSelectElement> | undefined;
}

export function SelectField(props: SelectFieldProps) {
	return (
		<div>
			{props.label && (
				<div className="flex items-center justify-between">
					<Label
						name={props.name}
						label={props.label}
						required={props.required || props.showRequiredAsterisk}
					/>
					{props.fieldAction && props.fieldAction}
				</div>
			)}
			<select
				id={props.name}
				name={props.name}
				value={props.value}
				disabled={props.disabled}
				required={props.required}
				onChange={props.handleSelect}
				className={classNames(
					props.label ? 'mt-1' : '',
					'shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100 disabled:text-gray-400 disabled:font-medium disabled:hover:cursor-not-allowed'
				)}
			>	
				{props.options && props.options.length ? (
					props.options.map((item, index) => (
						<option
							key={index}
							value={item.value}
							disabled={item.disabled}
						>
							{item.label}
						</option>
					))
				) : (
					<option value="" disabled={true}>
						No options available
					</option>
				)}
			</select>
		</div>
	);
}

export default SelectField;
