import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { LoadingPage } from '@pangea-lis-apps/ui';

interface ResultsProps {}

export function Results(props: ResultsProps) {
	const { loginWithRedirect } = useAuth0();

	useEffect(() => {
		loginWithRedirect({
			redirectUri: window.location.origin.concat('/callback/'),
		});
	}, []);

	return <LoadingPage />;
}

export default Results;
