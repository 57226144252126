import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
	useId,
	useState,
	Fragment,
	Dispatch,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Form,
	Modal,
	Button,
	useAxios,
	FormGroup,
	FormFooter,
	TextareaField,
} from '@pangea-lis-apps/ui';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HelpButtonProps {}

export default function HelpButton(props: HelpButtonProps) {
	const { pathname } = useLocation();

	const [modalVisible, setModalVisible] = useState(false);

	// const subject = 'Issue';
	// const email = 'support@pangealab.com';
	// const body = `Please provide the following details for the issue you're encountering:\n\nTime of occurrence: ${new Date().toString()}\nWhat are you trying to do?\nWhat is the expected response?\nWhat page are you on?\nDo you receive an error notification?`;

	// const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
	// 	subject
	// )}&body=${encodeURIComponent(body)}`;

	return ['/', '/callback', '/verify-email', '/access-pending'].includes(
		pathname
	) ? null : (
		<Fragment>
			<SendSupportMessageModal
				visible={{
					visible: modalVisible,
					setVisible: setModalVisible,
				}}
			/>
			<Popover className="z-10 fixed bottom-10 right-10">
				<Transition
					as={Fragment}
					enter="transition ease-out duration-200"
					enterFrom="opacity-0 translate-y-1"
					enterTo="opacity-100 translate-y-0"
					leave="transition ease-in duration-150"
					leaveFrom="opacity-100 translate-y-0"
					leaveTo="opacity-0 translate-y-1"
				>
					<Popover.Panel className="p-2 bg-slate-100 border border-slate-300 rounded-lg shadow-md absolute -top-20 -right-1 z-10 mb-3 w-screen max-w-xs">
						<ul className="divide-y divide-slate-300">
							<li className="pb-1">
								<button
									onClick={() => setModalVisible(true)}
									className="w-full text-left block rounded-md hover:bg-slate-200 text-slate-700 p-2"
								>
									Contact Support
								</button>
							</li>
							{/* <li className="pt-1">
								<button
									onClick={() =>
										window.open(
											'https://bentley-internal-test-submission-system-public.s3.amazonaws.com/sti-collection-instructions.pdf',
											'_blank'
										)
									}
									className="w-full text-left block rounded-md hover:bg-slate-200 text-slate-700 p-2"
								>
									Download STI Collection Instructions
								</button>
							</li> */}
						</ul>
					</Popover.Panel>
				</Transition>
				<Popover.Button className="outline-none">
					<div className="p-2 bg-slate-100 border border-slate-300 hover:bg-slate-200 cursor-pointer shadow-sm scale-125 rounded-full">
						<InformationCircleIcon className="w-5 h-5 text-gray-500" />
					</div>
				</Popover.Button>
			</Popover>
		</Fragment>
	);
}

const initialMessageValue =
	"Please provide the following details for the issue you're encountering:\n\nWhat page are you on?\nWhat action are you trying to perform?\nDid you receive an error notification? If so, what did it say?";

interface SendSupportMessageModalProps {
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
}

const SendSupportMessageModal = (props: SendSupportMessageModalProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();
	const { visible, setVisible } = props.visible;

	const [disabled, setDisabled] = useState(false);
	const [message, setMessage] = useState(initialMessageValue);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios || !user) return;

		setDisabled(true);

		toast.loading('Sending...', toastOptions);

		try {
			await (
				await axios
			).post(`/api/organization-shared/contact-support`, {
				user,
				message: message.concat('\n\n', new Date().toString()),
			});

			toast.success('Message sent!', toastOptions);

			handleClose();
		} catch (error) {
			console.log(error);

			toast.error('Message failed to send!', toastOptions);
			setDisabled(false);
		}
	};

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLTextAreaElement;

		if (target && target.name) setMessage(target.value);
	};

	const handleClose = () => {
		setDisabled(false);
		setVisible(false);
		setMessage(initialMessageValue);
	};

	return (
		<Modal
			visible={visible}
			onClose={handleClose}
			customWidth="max-w-xl"
			title="Contact Support"
			description="You can expect a response to the email address tied to this account."
		>
			<Form handleSubmit={handleSubmit}>
				<FormGroup>
					<div className="sm:col-span-6">
						<TextareaField
							required
							rows={10}
							name="message"
							label="Message"
							value={message}
							handleInputChange={handleChange}
						/>
					</div>
				</FormGroup>
				<FormFooter>
					<Button
						text="Send"
						type="submit"
						tier="tertiary"
						Icon="PaperAirplaneIcon"
					/>
				</FormFooter>
			</Form>
		</Modal>
	);
};
