import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useLoadingContext } from '../../context/loading/loading';

/* eslint-disable-next-line */
export interface LoadingModalProps {}

export function LoadingModal() {
	const { loading, setLoading } = useLoadingContext();

	return (
		<Transition.Root show={loading} as={Fragment}>
			<Dialog as="div" className="relative z-50" onClose={setLoading}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-100"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<img
							alt="logo"
							className="animate-spin-slow"
							src="https://bentley-internal-test-submission-system-public.s3.amazonaws.com/loading-page-spinner.svg"
						/>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
export default LoadingModal;
