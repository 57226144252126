import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect } from 'react';

import {
	useAxios,
	LoadingBox,
	ContentSection,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Option,
	Patient,
	formatDate,
	getLabelFromValue,
	getAllRequisitionFormOptions,
} from '@pangea-lis-apps/utils';

const raceOptions = Object.values(
	getAllRequisitionFormOptions('race_options', 'array')
);
const ethnicityOptions = Object.values(
	getAllRequisitionFormOptions('ethnicity_options', 'array')
);

/* eslint-disable-next-line */
export interface ViewPatientDetailsProps {}

export default function ViewPatientDetails(props: ViewPatientDetailsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();
	const { patientId } = useParams();

	const [patient, setPatient] = useState<Patient | undefined>(undefined);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!user || !axios) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(`/api/organization-shared/patients/${patientId}`);

				if (!unmounted) {
					const patientDate = JSON.parse(data);
					setPatient(patientDate);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, user, patientId]);

	return !patient ? (
		<LoadingBox />
	) : (
		<ContentSection heading="Info">
			<DescriptionList>
				<DescriptionItem
					term="Name"
					details={`${patient.first_name} ${
						patient.middle_name || ''
					} ${patient.last_name}`}
				/>
				<DescriptionItem
					term="Date of Birth"
					details={formatDate(patient.date_of_birth)}
				/>
				<DescriptionItem
					term="Medical Record Number (MRN)"
					details={patient.medical_record_number}
				/>
				<DescriptionItem term="Address">
					{patient.address}
					<br />
					{patient.city},{' '}
					{getLabelFromValue(patient.state, FORMS.states)}{' '}
					{patient.zip_code}
					<br />
					{getLabelFromValue(patient.country, FORMS.countries)}
				</DescriptionItem>
				<DescriptionItem
					term="Phone Number"
					details={patient.phone_number}
				/>
				<DescriptionItem
					term="Email Address"
					details={patient.email_address}
				/>
				<DescriptionItem
					term="Sex"
					details={getLabelFromValue(patient.sex, FORMS.sex_options)}
				/>
				<DescriptionItem
					term="Race"
					details={patient.race
						.map((race) =>
							getLabelFromValue(race, raceOptions as Option[])
						)
						.join(', ')}
				/>
				<DescriptionItem
					term="Ethnicity"
					details={getLabelFromValue(
						patient.ethnicity,
						ethnicityOptions as Option[]
					)}
				/>
			</DescriptionList>
		</ContentSection>
	);
}
