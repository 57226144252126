import { ReactNode } from 'react';

export interface DescriptionDetailsProps {
	children: ReactNode;
}

export function DescriptionDetails(props: DescriptionDetailsProps) {
	return (
		<dd className="mt-1 text-sm text-gray-900 break-words">
			{props.children}
		</dd>
	);
}

export default DescriptionDetails;
