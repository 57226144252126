export interface SectionHeadingProps {
	text: string;
	className?: string;
}

export function SectionHeading(props: SectionHeadingProps) {
	return (
		<h2
			className={`text-slate-500 text-xs font-semibold uppercase underline decoration-1 tracking-wide ${props.className}`}
		>
			{props.text}
		</h2>
	);
}

export default SectionHeading;
