/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useId,
	useState,
	Dispatch,
	Fragment,
	useEffect,
	FormEvent,
	ChangeEvent,
	SetStateAction,
	ChangeEventHandler,
} from 'react';

import {
	Modal,
	Button,
	useAxios,
	DateField,
	FileField,
	FormFooter,
	SelectField,
	GenericField,
	TextareaField,
	DescriptionItem,
	MultipleSelectField,
} from '@pangea-lis-apps/ui';
import { Attribute } from '@pangea-lis-apps/utils';
import { NoSymbolIcon } from '@heroicons/react/24/outline';

interface EditDataModalProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	heading: string;
	endpoint: string;
	subheading?: string;
	attribute: Attribute;
	visible: {
		visible: boolean;
		setVisible: Dispatch<SetStateAction<boolean>>;
	};
	customWidth?: string;
	setRefresh: Dispatch<SetStateAction<boolean>>;
}

export function EditDataModal(props: EditDataModalProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();

	const { visible, setVisible } = props.visible;

	const [newValue, setNewValue] = useState('');
	const [disabled, setDisabled] = useState(false);
	const [newFile, setNewFile] = useState<File | undefined>(undefined);
	const [multipleSelectNewValue, setMultipleSelectNewValue] = useState<
		string[]
	>([]);

	useEffect(() => {
		if (props.attribute.type === 'multiple_select') {
			// If value is '', then just return an empty string
			// Otherwise, split(', ') will generate [''], which selects the 'Select an Option' option
			setMultipleSelectNewValue(
				!props.attribute.value ? [] : props.attribute.value.split(', ')
			);
		}
	}, [props.attribute.type, props.attribute.value]);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (disabled || !axios) return;

		// Validation and payload creation
		let payload = null;

		if (props.attribute.type === 'file') {
			if (!newFile) {
				toast.error('Please choose a file!', toastOptions);
				return;
			}

			// Must use FormData for forms with files
			const formData = new FormData();

			formData.append('user', JSON.stringify(user));
			formData.append('file', newFile!);
			formData.append('property', props.attribute.property);
			formData.append('old_value', props.attribute.value);

			payload = formData;
		} else {
			let new_value = null;

			if (props.attribute.type === 'multiple_select') {
				if (
					multipleSelectNewValue.join(', ') === props.attribute.value
				) {
					toast.error('Please provide a new value!', toastOptions);
					return;
				}

				new_value = multipleSelectNewValue;
			} else {
				new_value = newValue.trim();

				if (!new_value || new_value === props.attribute.value) {
					toast.error('Please provide a new value!', toastOptions);
					return;
				}

				new_value = newValue;
			}

			payload = {
				user,
				update_data: {
					new_value: new_value,
					old_value: props.attribute.value,
					property: props.attribute.property,
				},
			};
		}

		setDisabled(true);

		toast.loading('Updating...', toastOptions);

		try {
			console.log('payload', payload);

			await (await axios).patch(props.endpoint, payload);

			toast.dismiss();

			handleCloseModal();
			props.setRefresh((prev) => !prev);
		} catch (error) {
			console.log(error);

			setDisabled(false);
		}
	};

	const handleInputChange = (event: ChangeEvent) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) setNewValue(target.value);
	};

	const handleMultipleSelectChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLSelectElement;

		if (target && target.name)
			setMultipleSelectNewValue(
				Array.from(target.selectedOptions, (item) => item.value)
			);
	};

	const handleCloseModal = () => {
		setNewValue('');
		setVisible(false);
		setDisabled(false);
		setSelectedOption('');
	};

	// For select fields with an other option
	const [selectedOption, setSelectedOption] = useState('');
	const handleSelectionChange: ChangeEventHandler<HTMLSelectElement> = (
		event: ChangeEvent
	) => {
		const target = event.target as HTMLInputElement | HTMLSelectElement;

		if (target && target.name) {
			setSelectedOption(target.value);
			setNewValue(target.value === 'other' ? '' : target.value);
		}
	};

	return (
		<Modal
			visible={visible}
			title={props.heading}
			onClose={handleCloseModal}
			description={props.subheading}
			customWidth={props.customWidth ? props.customWidth : 'w-92'}
		>
			<form onSubmit={handleSubmit}>
				<div className="space-y-4">
					{props.attribute.type === 'file' ? (
						<DescriptionItem term={props.attribute.label}>
							{props.attribute.fileTypeAccept === 'image/*' &&
							props.attribute.value ? (
								<div className="flex items-center justify-center bg-gray-50 border rounded-lg py-6 shadow-sm">
									<img
										alt="data"
										width="150"
										height="150"
										src={props.attribute.value}
										className="rounded-lg shadow-sm"
									/>
								</div>
							) : (
								<div className="flex flex-col items-center justify-center bg-gray-50 border rounded-lg py-6 shadow-sm space-y-1">
									<NoSymbolIcon className="w-5 h-5 text-gray-500" />
									<p className="text-gray-500">
										No image available
									</p>
								</div>
							)}
						</DescriptionItem>
					) : (
						<DescriptionItem
							term={props.attribute.label}
							details={
								props.attribute.selectLabel
									? props.attribute.selectLabel
									: props.attribute.value
							}
						/>
					)}
					{(() => {
						const { type, selectOptions } = props.attribute;

						if (type === 'select') {
							return (
								<Fragment>
									<SelectField
										required
										name="new_value"
										label="New value"
										value={selectedOption}
										options={selectOptions || []}
										handleSelect={handleSelectionChange}
									/>
									{selectedOption === 'other' && (
										<GenericField
											required
											type="text"
											name="new_value"
											value={newValue}
											label="If other, please specify"
											placeholder="Enter other value"
											handleInputChange={
												handleInputChange
											}
										/>
									)}
								</Fragment>
							);
						} else if (type === 'multiple_select') {
							return (
								<MultipleSelectField
									required
									name="new_value"
									label="New value"
									options={selectOptions || []}
									value={multipleSelectNewValue}
									handleSelect={handleMultipleSelectChange}
								/>
							);
						} else if (type === 'file') {
							return (
								<Fragment>
									<FileField
										required
										name="new_file"
										label="New file"
										accept={
											props.attribute.fileTypeAccept &&
											props.attribute.fileTypeAccept
										}
										handleInputChange={(event) => {
											const target =
												event.target as HTMLInputElement;

											if (target && target.files) {
												const file = target.files[0];

												setNewFile(file);
											}
										}}
									/>
								</Fragment>
							);
						} else if (type === 'date') {
							return (
								<DateField
									required
									name="new_value"
									value={newValue}
									label="New value"
									handleInputChange={handleInputChange}
								/>
							);
						} else if (type === 'textarea') {
							return (
								<TextareaField
									required
									name="new_value"
									value={newValue}
									label="New value"
									placeholder="Enter text"
									handleInputChange={handleInputChange}
								/>
							);
						} else {
							return (
								<GenericField
									required
									type={type}
									name="new_value"
									value={newValue}
									label="New value"
									placeholder="Enter value"
									handleInputChange={handleInputChange}
								/>
							);
						}
					})()}
				</div>
				<FormFooter>
					<Button
						type="submit"
						text="Update"
						tier="tertiary"
						Icon="CheckIcon"
						disabled={disabled}
					/>
				</FormFooter>
			</form>
		</Modal>
	);
}

export default EditDataModal;
