import { ReactNode } from 'react';
import * as Heroicons from '@heroicons/react/24/outline';

import { IconName } from '@pangea-lis-apps/utils';

export interface ContentWrapperProps {
	Icon?: IconName;
	heading?: string;
	subheading?: string;
	children: ReactNode;
	description?: ReactNode;
}

export function ContentWrapper(props: ContentWrapperProps) {
	const Icon = Heroicons[props.Icon || 'ExclamationCircleIcon'];

	return (
		<div className="space-y-8 pb-20">
			{props.heading && (
				<div className="bg-slate-50/30 border-b border-gray-200 px-4 py-4 sm:px-6 lg:px-8">
					<div className="flex items-center justify-between">
						{props.Icon && (
							<div className="flex-none inline-block border border-slate-200 shadow-sm bg-slate-100/50 p-3 rounded-xl text-center mr-4">
								<div className="rounded-full p-3 bg-slate-200/75">
									<Icon
										aria-hidden="true"
										className="h-7 w-7 text-slate-500"
									/>
								</div>
							</div>
						)}
						<div className="flex-1 min-w-0">
							{props.subheading && (
								<h2 className="text-xxs uppercase font-medium text-gray-500 mb-1">
									{props.subheading}
								</h2>
							)}
							<h1 className="text-xl font-medium leading-6 text-gray-900 sm:truncate">
								{props.heading}
							</h1>
							{props.description && (
								<p className="mt-1 text-sm text-gray-500">
									{props.description}
								</p>
							)}
						</div>
					</div>
				</div>
			)}

			{props.children}
		</div>
	);
}

export default ContentWrapper;
