import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect } from 'react';

import { useAxios } from '@pangea-lis-apps/ui';

export default function useViewReport() {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user: auth0_user } = useAuth0();

	const [canViewReport, setCanViewReport] = useState<boolean | undefined>(
		undefined
	);

	useEffect(() => {
		if (!axios || !auth0_user) return;

		const fetchData = async () => {
			try {
				const {
					data: { data: permissions },
				} = await (
					await axios
				).get(
					`/api/organization-staff/users/${auth0_user.sub}/permissions`
				);

				let canViewReports = false;

				if (permissions) {
					permissions.forEach((permission: any) => {
						if (permission.permission_name === 'read:report') {
							canViewReports = true;
						}
					});
				}

				setCanViewReport(canViewReports);
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();
	}, [auth0_user, axios]);

	return canViewReport;
}
