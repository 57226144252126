import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useId, useState, Fragment, useEffect } from 'react';

import {
	Table,
	DataCell,
	TableRow,
	useAxios,
	TableHead,
	TableBody,
	LoadingBox,
	EmptyTable,
	HeaderCell,
	TextButton,
	TableLoading,
	ContentSection,
	ArrowLinkButton,
	DescriptionItem,
	DescriptionList,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	Query,
	ASSAYS,
	Patient,
	ClinicData,
	Pagination,
	formatDate,
	QueryState,
	getAssayTests,
	PaginationState,
	getSpecimenTypes,
	getSampleStatuses,
	getLabelFromValue,
	getRoleFromPathname,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

/* eslint-disable-next-line */
export interface ViewPatientReportsProps {}

export default function ViewPatientReports(props: ViewPatientReportsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();
	const { patientId } = useParams();

	const [query, setQuery] = useState<Query>({});
	const [patient, setPatient] = useState<Patient | undefined>(undefined);
	const [data, setData] = useState<ClinicData[] | undefined | null>(
		undefined
	);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!user || !axios || !patientId) return;

			try {
				const [
					{
						data: { data: patientData },
					},
					{
						data: {
							data: { data: reportsData, total_entries },
						},
					},
				] = await Promise.all([
					await (
						await axios
					).get(`/api/organization-shared/patients/${patientId}`),
					await (
						await axios
					).get(
						`/api/organization-shared/patients/${patientId}/reports?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`
					),
				]);

				if (!unmounted) {
					const parsedPatientData = JSON.parse(patientData);
					console.log(parsedPatientData);
					setPatient(parsedPatientData);

					const parsedReportsData = JSON.parse(reportsData);
					setData(parsedReportsData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [
		user,
		axios,
		patientId,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return data === undefined || !patient ? (
		<LoadingBox />
	) : (
		<Fragment>
			<ContentSection heading="Patient Information">
				<DescriptionList>
					<DescriptionItem
						term="Name"
						details={`${patient.first_name} ${
							patient.middle_name || ''
						} ${patient.last_name}`}
					/>
					<DescriptionItem
						term="Date of Birth"
						details={formatDate(patient.date_of_birth)}
					/>
					<DescriptionItem
						term="Medical Record Number (MRN)"
						details={patient.medical_record_number}
					/>
				</DescriptionList>
			</ContentSection>

			<ContentSectionDivider />

			<ContentSection heading="Reports">
				<PatientReportsTable
					data={data}
					query={{ query, setQuery }}
					pagination={{
						pagination,
						setPagination,
					}}
				/>
			</ContentSection>
		</Fragment>
	);
}

interface PatientReportsTableProps {
	query: QueryState;
	data: ClinicData[] | null;
	pagination: PaginationState;
}

export function PatientReportsTable(props: PatientReportsTableProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const navigate = useNavigate();
	const role = getRoleFromPathname();

	const { user } = useAuth0();
	const assayTests = getAssayTests();

	const handleDownloadReport = async (dataId: string) => {
		if (!axios) return;

		toast.loading('Downloading...', toastOptions);

		try {
			const {
				data: { data },
			} = await (
				await axios
			).get(
				`/api/organization-shared/data/${dataId}/sample/report/?auth0_user_id=${user?.sub}`
			);

			toast.dismiss();

			window.open(data, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>Status</HeaderCell>
				<HeaderCell>Specimen ID</HeaderCell>
				<HeaderCell>Collection Kit ID</HeaderCell>
				<HeaderCell>Pangea ID</HeaderCell>
				<HeaderCell>Test</HeaderCell>
				<HeaderCell>Specimen Type</HeaderCell>
				<HeaderCell customWidth="w-72">Ordered Tests</HeaderCell>
				<HeaderCell customWidth="w-60">Collection Date</HeaderCell>
				<HeaderCell>Report date</HeaderCell>
				<HeaderCell>Report</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length > 0 ? (
						props.data.map((datum, index) => {
							const validEndStatus = [
								'reject',
								'verified',
							].includes(datum.sample.status);

							return (
								<TableRow key={index} index={index}>
									<DataCell>
										{getLabelFromValue(
											datum.sample.status,
											getSampleStatuses()
										)}
									</DataCell>
									<DataCell>
										<ArrowLinkButton
											text={
												datum.sample.specimen_id ||
												'N/A'
											}
											onClick={() =>
												navigate(
													`/${role}/specimens/${datum._id.$oid}`
												)
											}
										/>
									</DataCell>
									<DataCell>
										{datum.sample.collection_kit_id}
									</DataCell>
									<DataCell>
										{datum.sample.pangea_id}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.assay,
											ASSAYS['assays']
										)}
									</DataCell>
									<DataCell>
										{getLabelFromValue(
											datum.sample.sample_specimen_type,
											getSpecimenTypes()
										)}
									</DataCell>
									<DataCell>
										{datum.sample.order_tests.length > 0 ? (
											<ul className="list-disc list-inside">
												{datum.sample.order_tests.map(
													(
														test: string,
														index: number
													) => {
														const foundTest =
															assayTests.find(
																(x_test) =>
																	x_test.value ===
																	test
															);

														return (
															<li key={index}>
																{foundTest
																	? foundTest.label
																	: test}
															</li>
														);
													}
												)}
											</ul>
										) : null}
									</DataCell>
									<DataCell>
										{formatDate(
											datum.sample.sample_collection_date
										)}
									</DataCell>
									<DataCell>
										{validEndStatus
											? formatDate(
													datum.sample.status ===
														'verified'
														? datum.sample.report
																.verified
																.metadata.date
																?.$date
														: datum.sample
																.accessioning_approval
																.reject.reason
																.metadata.date
											  )
											: 'In Progress'}
									</DataCell>
									<DataCell>
										{validEndStatus ? (
											<TextButton
												color="blue"
												type="button"
												text="Download"
												onClick={() =>
													handleDownloadReport(
														datum._id.$oid
													)
												}
											/>
										) : (
											'In Progress'
										)}
									</DataCell>
								</TableRow>
							);
						})
					) : (
						<EmptyTable
							heading="No reports"
							Icon="DocumentChartBarIcon"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
