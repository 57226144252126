import { useAuth0 } from '@auth0/auth0-react';

import { Button, PublicPageWrapper } from '@pangea-lis-apps/ui';

interface AccessPendingProps {}

export function AccessPending(props: AccessPendingProps) {
	const { logout } = useAuth0();

	return (
		<PublicPageWrapper
			heading="Access Pending"
			subheading="Authorization"
			description={`${
				process.env['NX_PORTAL_TYPE'] === 'ASSOCIATE'
					? 'Contact the dev team to be assigned a role.'
					: 'Contact clientservices@pangealab.com to be assigned a role.'
			} Refresh this page once the appropriate role has been assigned.`}
		>
			<Button
				text="Logout"
				type="button"
				tier="primary"
				onClick={() => logout({ returnTo: window.location.origin })}
			/>
		</PublicPageWrapper>
	);
}

export default AccessPending;
