import { ReactNode } from 'react';

export interface BadgeProps {
	content: ReactNode;
	color?: 'green' | 'red' | 'orange';
}

export function Badge(props: BadgeProps) {
	// Set button styles
	let buttonStyles = '';

	if (props.color === 'green')
		buttonStyles =
			'text-green-600 bg-green-50 border border-green-400 hover:bg-green-100 focus:ring-green-200';
	else if (props.color === 'red')
		buttonStyles =
			'text-red-600 bg-red-50 border border-red-400 hover:bg-red-100 focus:ring-red-200';
	else if (props.color === 'orange')
		buttonStyles =
			'text-orange-600 bg-orange-50 border border-orange-400 hover:bg-orange-100 focus:ring-orange-200';
	else
		buttonStyles =
			'text-gray-600 bg-gray-50 border border-gray-400 hover:bg-gray-100 focus:ring-gray-200';

	return (
		<span
			className={`inline-flex items-center rounded px-2 py-0.5 text-xs font-medium ${buttonStyles}`}
		>
			{props.content}
		</span>
	);
}

export default Badge;
