import { useAuth0 } from '@auth0/auth0-react';
import { Outlet, useNavigate, Navigate, useLocation } from 'react-router-dom';

import {
	Container,
	SidebarNav,
	IconButton,
	LoadingPage,
	useTabsContext,
	ContentSection,
} from '@pangea-lis-apps/ui';

interface ProtectedRouteProps {
	profileHref: string;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { loginWithRedirect } = useAuth0();
	const { logout, isLoading, user, isAuthenticated, error } = useAuth0();

	const { allTabs } = useTabsContext();

	if (error) {
		if (error.message === 'user is blocked') {
			if (pathname !== '/blocked') {
				return <Navigate to="/blocked" replace />;
			} else {
				return null;
			}
		} else {
			logout({
				returnTo: window.location.origin,
			});

			return null;
		}
	}

	if (isLoading) {
		return <LoadingPage />;
	} else {
		if (isAuthenticated) {
			if (user && allTabs) {
				if (!user.email_verified) {
					return <Navigate to="/verify-email" replace />;
				}

				if (allTabs.length === 0) {
					return <Navigate to="/access-pending" replace />;
				} else {
					// Set current tab
					const userIsStaffMember = user[
						'custom_claims/roles'
					].includes('BITSS_organization-staff');

					if (userIsStaffMember) {
						sessionStorage.setItem(
							'currentTab',
							allTabs[0].children[0].id
						);
					} else {
						sessionStorage.setItem(
							'currentTab',
							allTabs[0].tabs[0].children
								? allTabs[0].tabs[0].children[0].id
								: allTabs[0].tabs[0].id
						);
					}

					return (
						<SidebarNav profileHref={props.profileHref}>
							<div className="w-full flex flex-col">
								<div>
									<Container className="border-b border-gray-200 shadow-sm">
										<ContentSection className="p-4">
											<IconButton
												Icon="ArrowLeftIcon"
												color="gray"
												type="button"
												onClick={() => navigate(-1)}
											/>
										</ContentSection>
									</Container>
								</div>

								<Outlet />
							</div>
						</SidebarNav>
					);
				}
			} else {
				return <LoadingPage />;
			}
		} else {
			loginWithRedirect({
				appState: {
					returnTo: window.location.pathname,
				},
				redirectUri: window.location.origin.concat('/callback/'),
			});

			return null;
		}
	}
};

export default ProtectedRoute;
