import toast from 'react-hot-toast';
import { Dispatch, Fragment, ReactNode, SetStateAction, useId } from 'react';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	Alert,
	Address,
	useAxios,
	TextButton,
	ReportVersions,
	ReportComments,
	ContentSection,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	FieldProps,
	formatDate,
	formatName,
	getLabelFromValue,
	getAllRequisitionFormOptions,
} from '@pangea-lis-apps/utils';

const ProviderInformation = ({ data, headingAction }: FieldProps) => {
	return (
		<ContentSection heading="Provider Info" headingAction={headingAction}>
			<DescriptionList>
				<DescriptionItem
					term="Name"
					details={formatName({
						firstName: data.customer.first_name,
						middleName: null,
						lastName: data.customer.last_name,
					})}
				/>
				<DescriptionItem
					term="Phone Number"
					details={data.customer.phone_number}
				/>
				<DescriptionItem term="Address">
					<Address
						data={{
							address: data.customer.address,
							city: data.customer.city,
							state: data.customer.state,
							zipCode: data.customer.zip_code,
							country: data.customer.country,
						}}
					/>
				</DescriptionItem>
			</DescriptionList>
		</ContentSection>
	);
};

const PatientInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic' && data.patient !== null) {
		return (
			<ContentSection
				headingAction={headingAction}
				heading="Patient Information"
			>
				<DescriptionList>
					<DescriptionItem
						term="Name"
						details={formatName({
							firstName: data.patient.first_name,
							middleName: data.patient.middle_name,
							lastName: data.patient.last_name,
						})}
					/>
					<DescriptionItem
						term="Date of Birth"
						details={formatDate(data.patient.date_of_birth)}
					/>
					<DescriptionItem
						term="Medical Record Number (MRN)"
						details={data.patient.medical_record_number}
					/>
					<DescriptionItem
						term="Sex"
						details={getLabelFromValue(
							data.patient.sex,
							FORMS.sex_options
						)}
					/>
					<DescriptionItem
						term="Ethnicity"
						details={getLabelFromValue(
							data.patient.ethnicity,
							Object.values(
								getAllRequisitionFormOptions(
									'ethnicity_options',
									'array'
								)
							)
						)}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem term="Address">
						<Address
							data={{
								address: data.patient.address,
								city: data.patient.city,
								state: data.patient.state,
								zipCode: data.patient.zip_code,
								country: data.patient.country,
							}}
						/>
					</DescriptionItem>
					<div className="sm:col-span-2"></div>
					<DescriptionItem
						term="Phone Number"
						details={data.patient.phone_number}
					/>
					<DescriptionItem
						term="Email Address"
						details={data.patient.email_address}
					/>
				</DescriptionList>
			</ContentSection>
		);
	}

	return null;
};

const OrganizationInformation = ({ data, headingAction }: FieldProps) => {
	return (
		<ContentSection
			heading="Organization Info"
			headingAction={headingAction}
		>
			<DescriptionList>
				<DescriptionItem term="Name" details={data.organization.name} />
				<DescriptionItem
					term="Phone Number"
					details={data.organization.phone_number}
				/>
				<DescriptionItem term="Address">
					<Address
						data={{
							address: data.organization.address,
							city: data.organization.city,
							state: data.organization.state,
							zipCode: data.organization.zip_code,
							country: data.organization.country,
						}}
					/>
				</DescriptionItem>
			</DescriptionList>
		</ContentSection>
	);
};

const ReportInformation = ({
	data,
	children,
	setRefresh,
}: FieldProps & {
	setRefresh: Dispatch<SetStateAction<boolean>>;
	children?: ReactNode;
}) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const handleDownloadReport = async () => {
		if (!axios || !data || !data.sample.report.aws_object_key) return;

		toast.loading('Downloading...', toastOptions);

		try {
			const {
				data: { data: report_data },
			} = await (
				await axios
			).get(
				`/api/cls/report?aws_object_key=${data.sample.report.aws_object_key}`
			);

			toast.dismiss();

			window.open(report_data, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<ContentSection
			heading="Report info"
			// description="The latest report version will always be used when generating a report. If the current report version does not have the same major version as the latest report version (e.g., 9.X and 10.X), a comment will be added regarding the update."
		>
			{!data.customer.account_activated && (
				<Alert
					type="warning"
					className="mb-4"
					heading="Account not activated"
					description="The customer's account is not yet activated, so they cannot access their reports via the portal. Contact the account manager to have them activate the customer's account."
				/>
			)}
			<DescriptionList>
				<DescriptionItem term="Report PDF">
					{data.sample.report.aws_object_key ? (
						<TextButton
							color="blue"
							type="button"
							text="Download"
							onClick={handleDownloadReport}
						/>
					) : (
						'Not available'
					)}
				</DescriptionItem>
				<div className="sm:col-span-2"></div>
				<DescriptionItem
					term="Verified?"
					details={data.sample.report.verified.value ? 'Yes' : 'No'}
				/>
				<DescriptionItem
					term="Verification Date"
					details={formatDate(
						data.sample.report.verified.metadata.date &&
							data.sample.report.verified.metadata.date.$date
					)}
				/>
				<DescriptionItem
					term="Verifier"
					details={
						data.sample.report.verified.metadata.by &&
						data.sample.report.verified.metadata.by.first_name.concat(
							' ',
							data.sample.report.verified.metadata.by.last_name
						)
					}
				/>
				{children && children}
				<div className="sm:col-span-2"></div>
				<ReportComments data={data} setRefresh={setRefresh} />
				<ReportVersions data={data} />
			</DescriptionList>
		</ContentSection>
	);
};

export const DataComponents = {
	ReportInformation,
	PatientInformation,
	ProviderInformation,
	OrganizationInformation,
};

export default DataComponents;
