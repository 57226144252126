import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect } from 'react';

import { useAxios, LoadingPage, PageNotFound } from '@pangea-lis-apps/ui';
import { Auth0Permission } from '@pangea-lis-apps/utils';

interface ClinicProtectedRouteProps {
	requiredPermissionForStaff: 'read:patient' | 'read:report' | 'read:sample';
}

export const ClinicProtectedRoute = ({
	requiredPermissionForStaff,
}: ClinicProtectedRouteProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();
	const [hasPermissions, setHasPermissions] = useState<boolean | null>(null);

	useEffect(() => {
		if (!user || !axios) return;

		const checkPermissions = async () => {
			const isAssociate = user['custom_claims/roles'].includes(
				'BITSS_organization-associate'
			);

			if (isAssociate) {
				setHasPermissions(true);
				return;
			}

			const isStaff = user['custom_claims/roles'].includes(
				'BITSS_organization-staff'
			);

			if (isStaff) {
				try {
					// Get the group id permissions from the backend
					const {
						data: { data: permissions },
					} = await (
						await axios
					).get(
						`/api/organization-staff/users/${user.sub}/permissions`
					);

					const permissionNames = permissions.map(
						(permission: Auth0Permission) =>
							permission.permission_name
					);

					const allowed = permissionNames.includes(
						requiredPermissionForStaff
					);

					setHasPermissions(allowed);
					return;
				} catch (error) {
					console.log(error);
				}
			}

			setHasPermissions(false);
		};

		checkPermissions();
	}, [user, axios, requiredPermissionForStaff]);

	if (hasPermissions === null) return <LoadingPage />;
	if (!hasPermissions) return <PageNotFound />;
	return <Outlet />;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OrganizationProtectedRouteProps {}

export const NonClinicAssociateProtectedRoute = (
	props: OrganizationProtectedRouteProps
) => {
	const { user } = useAuth0();
	const [hasPermissions, setHasPermissions] = useState(null);

	useEffect(() => {
		if (!user) return;

		setHasPermissions(
			user &&
				user['custom_claims/roles'].includes(
					'BITSS_nonclinic-organization-associate'
				)
		);
	}, [user]);

	if (hasPermissions === null) return <LoadingPage />;
	if (!hasPermissions) return <PageNotFound />;
	return <Outlet />;
};
