import { ReactNode } from 'react';

import { classNames } from '@pangea-lis-apps/utils';
import { SectionHeading, SectionDescription } from '@pangea-lis-apps/ui';

export interface FormGroupProps {
	styles?: string;
	heading?: string;
	className?: string;
	children: ReactNode;
	description?: ReactNode;
}

export function FormGroup(props: FormGroupProps) {
	return (
		<div className={props.className}>
			{props.heading && <SectionHeading text={props.heading} />}
			{props.description && (
				<SectionDescription styles="mt-2" content={props.description} />
			)}
			<div
				className={classNames(
					props.heading ? 'mt-4' : '',
					'grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'
				)}
			>
				{props.children}
			</div>
		</div>
	);
}

export default FormGroup;
