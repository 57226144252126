import { ChangeEventHandler, ReactNode } from 'react';

import { Label } from '../label/label';

export interface TextareaFieldProps {
	name: string;
	value: string;
	rows?: number;
	required?: true;
	label?: ReactNode;
	minLength?: number;
	maxLength?: number;
	placeholder?: string;
	description?: ReactNode;
	handleInputChange: ChangeEventHandler<HTMLTextAreaElement> | undefined;
}

export function TextareaField(props: TextareaFieldProps) {
	return (
		<div>
			{props.label && (
				<Label
					name={props.name}
					label={props.label}
					required={props.required}
					description={props.description || ''}
				/>
			)}
			<textarea
				id={props.name}
				name={props.name}
				value={props.value}
				required={props.required}
				maxLength={props.maxLength}
				minLength={props.minLength || 5}
				placeholder={props.placeholder}
				rows={props.rows ? props.rows : 5}
				onChange={props.handleInputChange}
				className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm focus:ring-2 focus:ring-blue-200 focus:border-blue-500 placeholder:italic placeholder:text-gray-400"
			/>
		</div>
	);
}

export default TextareaField;
