import { FORMS, getLabelFromValue } from '@pangea-lis-apps/utils';

export interface AddressProps {
	data: {
		address: string | null;
		city: string | null;
		state: string | null;
		zipCode: string | null;
		country: string | null;
	};
}

export function Address({
	data: { address, city, state, zipCode, country },
}: AddressProps) {
	return (
		<p>
			{address}
			<br />
			{city}, {state && getLabelFromValue(state, FORMS.states)} {zipCode}
			<br />
			{country && getLabelFromValue(country, FORMS.countries)}
		</p>
	);
}

export default Address;
