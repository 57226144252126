import { useId } from 'react';
import toast from 'react-hot-toast';

import { useAxios, Button, DescriptionItem } from '@pangea-lis-apps/ui';

import { ClinicData, requiredStyles } from '@pangea-lis-apps/utils';

interface AttachmentsProps {
	data: ClinicData;
	showRequiredStyles: boolean;
	entity: 'accessioner' | 'client-services' | 'organization-shared' | 'cls';
}

export function Attachments({
	data,
	entity,
	showRequiredStyles,
}: AttachmentsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const handleDownloadAttachment = async (aws_object_key: string) => {
		if (!axios) return;

		try {
			toast.loading('Downloading...', toastOptions);

			const {
				data: { data: file },
			} = await (
				await axios
			).get(
				`/api/${entity}/data/${
					data._id.$oid
				}/trf/attachment/?aws_object_key=${encodeURIComponent(
					aws_object_key
				)}`
			);

			toast.dismiss();

			window.open(file, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<DescriptionItem
			term="Attachments"
			termStyles={
				showRequiredStyles
					? requiredStyles(data.requisition_form.attachments)
					: ''
			}
			customColSpan="sm:col-span-3"
		>
			{data.requisition_form.attachments.length ? (
				<div className="space-y-2">
					{data.requisition_form.attachments.map(
						(
							attachment: {
								aws_object_key: string;
								file_name: string;
							},
							index: number
						) => {
							return (
								<div
									key={index}
									className="flex items-center space-x-4"
								>
									<Button
										type="button"
										tier="tertiary"
										text="Download"
										Icon="ArrowDownTrayIcon"
										onClick={() =>
											handleDownloadAttachment(
												attachment.aws_object_key
											)
										}
									/>
									<p className="text-sm">
										{attachment.file_name}
									</p>
								</div>
							);
						}
					)}
				</div>
			) : null}
		</DescriptionItem>
	);
}
