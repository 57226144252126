import { Menu, Transition } from '@headlessui/react';
import * as SolidHeroicons from '@heroicons/react/24/solid';
import * as OutlineHeroicons from '@heroicons/react/24/outline';
import { ArrowSmallRightIcon } from '@heroicons/react/24/outline';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import {
	useState,
	Fragment,
	ReactNode,
	forwardRef,
	MouseEventHandler,
} from 'react';

import { IconName, classNames } from '@pangea-lis-apps/utils';

export type ButtonTier = 'primary' | 'secondary' | 'tertiary' | 'error';

type ButtonProps = {
	text: ReactNode;
	formId?: string;
	Icon?: IconName;
	tier: ButtonTier;
	className?: string;
	disabled?: boolean;
	dataAttribute?: string;
	type: 'submit' | 'button' | 'reset';
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined; // button may be enclosed in a form and an onClick may not be necessary
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		const Icon = OutlineHeroicons[props.Icon || 'ExclamationCircleIcon'];

		// Set button styles
		let buttonStyles = '';

		if (props.tier === 'primary') {
			buttonStyles =
				'text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-200';
		} else if (props.tier === 'secondary') {
			buttonStyles =
				'text-blue-600 bg-blue-50 border border-blue-200 hover:bg-blue-100 focus:ring-blue-200';
		} else if (props.tier === 'tertiary') {
			buttonStyles =
				'text-gray-600 bg-gray-50 border border-gray-200 hover:bg-gray-100 focus:ring-gray-200';
		} else {
			buttonStyles =
				'text-white bg-red-600 hover:bg-red-700 focus:ring-red-200';
		}

		return (
			<button
				ref={ref}
				form={props.formId}
				onClick={props.onClick}
				disabled={props.disabled}
				type={props.type ? props.type : 'button'}
				data-custom-attribute={props.dataAttribute}
				className={classNames(
					buttonStyles,
					props.className ? props.className : '',
					'inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md shadow-sm disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2'
				)}
			>
				{props.Icon && (
					<Icon aria-hidden="true" className="-ml-1 mr-2 h-4 w-4" />
				)}
				{props.text}
			</button>
		);
	}
);

export interface FilterButtonProps {
	text: string;
	Icon: IconName;
	type: 'button' | 'submit' | 'reset';
	onClick: (filterOn?: boolean) => void;
}

export function FilterButton(props: FilterButtonProps) {
	const [filterOn, setFilterOn] = useState(false);
	const Icon = OutlineHeroicons[props.Icon || 'ExclamationCircleIcon'];

	return (
		<button
			type={props.type}
			onClick={() => {
				props.onClick(!filterOn);
				setFilterOn(!filterOn);
			}}
			className={classNames(
				filterOn
					? 'text-blue-600 bg-blue-50 border-blue-200 hover:bg-blue-100 focus:ring-blue-200'
					: 'text-gray-600 bg-gray-50 border-gray-200 hover:bg-gray-100 focus:ring-gray-200',
				'rounded-md text-sm font-medium shadow-sm border inline-flex items-center justify-center px-4 py-2 hover:shadow focus:outline-none focus:ring-2'
			)}
		>
			<Icon
				aria-hidden="true"
				className={classNames(
					filterOn ? 'text-blue-600' : 'text-gray-500',
					'w-5 h-5 mr-2'
				)}
			/>
			{props.text}
		</button>
	);
}

export interface IconButtonProps {
	Icon: IconName;
	className?: string;
	onClick: () => void;
	color: 'gray' | 'blue' | 'orange';
	type: 'button' | 'submit' | 'reset';
}

export function IconButton(props: IconButtonProps) {
	const Icon = SolidHeroicons[props.Icon || 'ExclamationCircleIcon'];

	const iconColor =
		props.color === 'blue'
			? 'text-blue-600 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
			: props.color === 'orange'
			? 'text-orange-600 hover:text-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
			: 'text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300';

	return (
		<button
			type={props.type}
			onClick={props.onClick}
			className={props.className}
		>
			<Icon className={`h-4 w-4 ${iconColor}`} aria-hidden="true" />
		</button>
	);
}

interface TextButtonProps {
	text: ReactNode;
	className?: string;
	onClick: () => void;
	color: 'blue' | 'gray';
	type: 'submit' | 'button' | 'reset';
}

export function TextButton(props: TextButtonProps) {
	return (
		<button
			type={props.type}
			onClick={(event) => {
				event.preventDefault();

				props.onClick();
			}}
			className={classNames(
				props.color === 'blue'
					? 'text-blue-600 hover:text-blue-800'
					: 'text-gray-500 hover:text-gray-700',
				'text-sm font-medium p-0',
				props.className ? props.className : ''
			)}
		>
			{props.text}
		</button>
	);
}

interface ArrowLinkButtonProps {
	text: string;
	onClick: () => void;
}

export function ArrowLinkButton(props: ArrowLinkButtonProps) {
	return (
		<button
			onClick={props.onClick}
			className="group flex items-center content-start space-x-0.5 cursor-pointer w-full"
		>
			<p className="text-sm font-medium text-blue-600 text-left group-hover:text-blue-700 text-ellipsis overflow-hidden">
				{props.text}
			</p>
			<ArrowSmallRightIcon className="flex-none w-4 h-4 text-blue-600 group-hover:text-blue-700" />
		</button>
	);
}

interface ButtonWithDropdownProps {
	Icon?: IconName;
	disabled?: boolean;
	mainDisabled?: boolean;
	mainClick: () => void;
	mainButtonText: string;
	items: {
		name: string;
		disabled?: boolean;
		handleClick: () => void;
	}[];
}

export function ButtonWithDropdown(props: ButtonWithDropdownProps) {
	const Icon = OutlineHeroicons[props.Icon || 'ExclamationCircleIcon'];
	const moreThanOneItem = props.items.length > 0;

	return (
		<div className="inline-flex rounded-md shadow-sm">
			<button
				type="button"
				onClick={props.mainClick}
				disabled={props.disabled || props.mainDisabled}
				className={classNames(
					'relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:opacity-50 disabled:cursor-not-allowed',
					moreThanOneItem ? 'rounded-l-md' : '!rounded-md'
				)}
			>
				{props.Icon ? (
					<Icon aria-hidden="true" className="-ml-1 mr-2 h-4 w-4" />
				) : (
					<CheckIcon
						aria-hidden="true"
						className="-ml-1 mr-2 h-4 w-4"
					/>
				)}
				{props.mainButtonText}
			</button>
			{moreThanOneItem && (
				<Menu as="div" className="relative -ml-px block">
					<Menu.Button
						as="button"
						disabled={props.disabled}
						className={classNames(
							'relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:opacity-50 disabled:cursor-not-allowed'
						)}
					>
						<span className="sr-only">Open options</span>
						<ChevronDownIcon
							className="h-5 w-5"
							aria-hidden="true"
						/>
					</Menu.Button>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="py-1">
								{props.items.map((item) => (
									<Menu.Item key={item.name}>
										{({ active }) => (
											<button
												disabled={item.disabled}
												onClick={item.handleClick}
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm w-full text-left disabled:opacity-50 disabled:cursor-not-allowed'
												)}
											>
												{item.name}
											</button>
										)}
									</Menu.Item>
								))}
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
			)}
		</div>
	);
}
