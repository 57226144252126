import { Date as DateType } from './common-types';

export const formatName = ({
	firstName,
	middleName,
	lastName,
}: {
	firstName: string | null;
	middleName: string | null;
	lastName: string | null;
}) => {
	return `${firstName}${
		middleName ? ' ' + middleName : ''
	} ${lastName}`.trim();
};

export const formatDate = (
	dateString: string | DateType | undefined | null,
	withTime = false
) => {
	if (dateString === undefined || dateString === null) return null;

	let parsedDateString: string | DateType = dateString;

	if (typeof parsedDateString === 'object' && '$date' in parsedDateString)
		parsedDateString = parsedDateString.$date;

	const htmlDateInputRegex = /^\d{4}-\d{2}-\d{2}$/;
	const dbUTCDateRegex1 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
	const dbUTCDateRegex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

	if (htmlDateInputRegex.test(parsedDateString)) {
		const [year, month, day] = parsedDateString.split('-');

		return `${month}/${day}/${year}`;
	} else if (
		dbUTCDateRegex1.test(parsedDateString) ||
		dbUTCDateRegex2.test(parsedDateString)
	) {
		return new Intl.DateTimeFormat([], {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: withTime ? 'numeric' : undefined,
			minute: withTime ? 'numeric' : undefined,
			hour12: withTime ? true : undefined,
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		}).format(new Date(parsedDateString));
	} else return parsedDateString;
};

export const capitalize = (string: string | undefined | null) => {
	if (string) return string.charAt(0).toUpperCase() + string.slice(1);

	return 'N/A';
};

export const convertDateTimeToYYYYMMDD = (datetimeString: string) => {
	const date = new Date(datetimeString);
	const isoDateString = date.toISOString().split('T')[0];

	return isoDateString;
};
