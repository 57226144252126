import {
	FieldProps,
	MiscAndAttachmentsFieldProps,
} from '@pangea-lis-apps/utils';

import V1_0 from './v1_0';
import Fallback from './fallback';
import DataComponents from '../../shared/data-components';

const ProviderInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '1.0') return <V1_0.ProviderInformation {...props} />;
		else return <Fallback.ProviderInformation {...props} />;
	}

	return <DataComponents.ProviderInformation {...props} />;
};

const OrganizationInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '1.0')
			return <V1_0.OrganizationInformation {...props} />;
		else return <Fallback.OrganizationInformation {...props} />;
	}

	return <DataComponents.OrganizationInformation {...props} />;
};

const OrderAndSpecimenInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '1.0')
			return <V1_0.OrderAndSpecimenInformation {...props} />;
		else return <Fallback.OrderAndSpecimenInformation {...props} />;
	}

	return null;
};

const PatientInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '1.0') return <V1_0.PatientInformation {...props} />;
		else return <Fallback.PatientInformation {...props} />;
	}

	return null;
};

const BillingInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '1.0') return <V1_0.BillingInformation {...props} />;
		else return <Fallback.BillingInformation {...props} />;
	}

	return null;
};

const MiscAndAttachments = (props: MiscAndAttachmentsFieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '1.0') return <V1_0.MiscAndAttachments {...props} />;
		else return <Fallback.MiscAndAttachments {...props} />;
	}

	return null;
};

export const Resp4PlexComponents = {
	BillingInformation,
	PatientInformation,
	ProviderInformation,
	OrganizationInformation,
	OrderAndSpecimenInformation,
	MiscAndAttachments,
};

export default Resp4PlexComponents;
