import { ReactNode } from 'react';

import { classNames } from '@pangea-lis-apps/utils';

export interface TableRowProps {
	index: number;
	className?: string;
	children: ReactNode;
}

export function TableRow(props: TableRowProps) {
	return (
		<tr
			className={classNames(
				'divide-x divide-gray-200 hover:!bg-blue-50',
				props.className ? props.className : '',
				props.index % 2 === 1 ? 'bg-gray-50' : 'bg-white'
			)}
		>
			{props.children}
		</tr>
	);
}

export default TableRow;
