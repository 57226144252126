import shared from './shared.json';
import { FIT, FIT_VERSIONS } from './fit';
import { STI, STI_VERSIONS } from './sti';
import { RESP4PLEX, RESP4PLEX_VERSIONS } from './resp4plex';
import { BLADDERCARE, BLADDERCARE_VERSIONS } from './bladdercare';

import { Option } from '../../../common-types';

export const VERSIONS: { [key: string]: Option[] } = {
	fit: FIT_VERSIONS,
	sti: STI_VERSIONS,
	resp4plex: RESP4PLEX_VERSIONS,
	bladdercare: BLADDERCARE_VERSIONS,
};

export { STI, RESP4PLEX, BLADDERCARE, FIT };

export const REQUISITION_FORM: { [key: string]: any } = {
	shared,
	fit: FIT,
	sti: STI,
	resp4plex: RESP4PLEX,
	bladdercare: BLADDERCARE,
};

export const getAllRequisitionFormOptions = (
	propertyName: string,
	asObjectOrArray: 'obj' | 'array' = 'obj'
) => {
	const arr = [];
	const keys = Object.keys(REQUISITION_FORM);

	for (let i = 0; i < keys.length; i++) {
		const assay = keys[i];

		if (assay === 'shared') {
			arr.push(REQUISITION_FORM['shared']);
		} else {
			// Dump all the version keys into the shared array.
			const versions = Object.keys(REQUISITION_FORM[assay]);

			// Dump everything including the shared
			for (let i = 0; i < versions.length; i++) {
				arr.push(REQUISITION_FORM[assay][versions[i]]);
			}

			// // Only get the versions (exclude the shared)
			// for (let i = 0; i < versions.length; i++) {
			// 	const versionRegex = /^\d+\.\d+\d*$/;

			// 	if (versionRegex.test(versions[i]))
			// 		return REQUISITION_FORM[assay][versions[i]];
			// }
		}
	}

	let result: any = asObjectOrArray === 'array' ? [] : {};

	arr.forEach((obj) => {
		if (propertyName in obj) {
			if (asObjectOrArray === 'array') {
				result = [...result, ...obj[propertyName]];
			} else {
				result[propertyName] = {
					...(result[propertyName] || {}),
					...obj[propertyName],
				};
			}
		}

		return result;
	});

	if (asObjectOrArray === 'array') {
		return (result as Option[]).filter(
			(item, index, self) =>
				index === self.findIndex((t) => t.value === item.value)
		);
	}

	return result[propertyName];
};
