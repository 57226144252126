import { ReactNode } from 'react';

import { classNames } from '@pangea-lis-apps/utils';
import { SectionHeading, SectionDescription } from '@pangea-lis-apps/ui';

export interface DescriptionListProps {
	heading?: string;
	className?: string;
	children: ReactNode;
	description?: string;
	headingAction?: ReactNode;
}

export function DescriptionList(props: DescriptionListProps) {
	return (
		<div className={props.className}>
			<div className="flex items-center justify-between">
				{props.heading && <SectionHeading text={props.heading} />}{' '}
				{props.headingAction && props.headingAction}
			</div>
			{props.description && (
				<SectionDescription content={props.description} styles="mt-2" />
			)}
			<dl
				className={classNames(
					props.heading ? 'mt-4' : '',
					'grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 md:grid-cols-3'
				)}
			>
				{props.children}
			</dl>
		</div>
	);
}

export default DescriptionList;
