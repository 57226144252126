import { ReactNode } from 'react';
import { classNames } from '@pangea-lis-apps/utils';

export interface LabelProps {
	name: string;
	label: ReactNode;
	required?: boolean;
	description?: string | ReactNode;
}

export function Label(props: LabelProps) {
	return (
		<div>
			<label
				htmlFor={props.name}
				className={classNames(
					props.required
						? "after:content-['*'] after:ml-0.5 after:text-blue-500"
						: '',
					'block text-sm font-medium text-gray-700'
				)}
			>
				{props.label}
			</label>
			{props.description && (
				<p className="mt-1 mb-2 text-xs text-gray-500">
					{props.description}
				</p>
			)}
		</div>
	);
}

export default Label;
