/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactNode } from 'react';

import { Assay, ClinicData, NonClinicData } from '@pangea-lis-apps/utils';

import STIComponents from '../sti';
import Resp4PlexComponents from '../resp4plex';
import BladderCAREComponents from '../bladdercare';
import PrecisionBIOMEWoundComponents from '../precision-biome-wound';
import FITComponents from '../fit'

interface FieldProps {
	hide?: string[];
	headingAction?: ReactNode;
	data: ClinicData | NonClinicData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const assays: { [key in Assay]: any } = {
	sti: STIComponents,
	resp4plex: Resp4PlexComponents,
	bladdercare: BladderCAREComponents,
	precision_biome_wound: PrecisionBIOMEWoundComponents,
	fit: FITComponents
};

export const ProviderInformation = (props: FieldProps) => {
	const Components = assays[props.data.sample.assay as Assay];

	if (!Components || !('ProviderInformation' in Components)) return null;

	return <Components.ProviderInformation {...props} />;
};

export const OrganizationInformation = (props: FieldProps) => {
	const Components = assays[props.data.sample.assay as Assay];

	if (!Components || !('OrganizationInformation' in Components)) return null;

	return <Components.OrganizationInformation {...props} />;
};

export const OrderAndSpecimenInformation = (props: FieldProps) => {
	const Components = assays[props.data.sample.assay as Assay];

	if (!Components || !('OrderAndSpecimenInformation' in Components))
		return null;

	return <Components.OrderAndSpecimenInformation {...props} />;
};

export const PatientInformation = (props: FieldProps) => {
	const Components = assays[props.data.sample.assay as Assay];

	if (!Components || !('PatientInformation' in Components)) return null;

	return <Components.PatientInformation {...props} />;
};

export const RelevantClinicalInformation = (props: FieldProps) => {
	const Components = assays[props.data.sample.assay as Assay];

	if (!Components || !('RelevantClinicalInformation' in Components))
		return null;

	return <Components.RelevantClinicalInformation {...props} />;
};

export const BillingInformation = (props: FieldProps) => {
	const Components = assays[props.data.sample.assay as Assay];

	if (!Components || !('BillingInformation' in Components)) return null;

	return <Components.BillingInformation {...props} />;
};

interface MiscAndAttachmentsFieldProps extends FieldProps {
	entity: 'accessioner' | 'client-services' | 'organization-shared' | 'cls';
}

export const MiscAndAttachments = (props: MiscAndAttachmentsFieldProps) => {
	const Components = assays[props.data.sample.assay as Assay];

	if (!Components || !('MiscAndAttachments' in Components)) return null;

	return <Components.MiscAndAttachments {...props} />;
};

export const TRFComponents = {
	BillingInformation,
	PatientInformation,
	ProviderInformation,
	OrganizationInformation,
	RelevantClinicalInformation,
	OrderAndSpecimenInformation,
	MiscAndAttachments,
};

export default TRFComponents;
