import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { default as BaseAxios, AxiosInstance, AxiosResponse } from 'axios';

export function useAxios(toastId: string) {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { loginWithRedirect, getAccessTokenSilently } = useAuth0();

	const [axios, setAxios] = useState<Promise<AxiosInstance> | null>(null);

	useEffect(() => {
		let unmounted = false;

		const handleLogout = () => {
			localStorage.clear();
			sessionStorage.clear();

			loginWithRedirect({
				appState: {
					returnTo: window.location.pathname,
				},
				redirectUri: window.location.origin.concat('/callback/'),
			});
		};

		const setAxiosInstance = () => {
			try {
				const instance = BaseAxios.create({
					baseURL:
						process.env['NODE_ENV'] === 'development'
							? `http://localhost:${process.env['NX_PORT']}`
							: process.env['NX_BACKEND_ENDPOINT'],
				});

				instance.defaults.withCredentials = true;
				instance.interceptors.request.use(
					async (config) => {
						try {
							const token = await getAccessTokenSilently({
								audience: process.env['NX_AUTH0_APP_AUDIENCE'],
							});

							return {
								...config,
								headers: {
									'X-App-Identifier-PGL-LIS':
										'LIS-PORTAL/1.0',
									Authorization: `Bearer ${token}`,
								},
							};
						} catch (error) {
							console.log(error);
							console.error('Token retrieval error: Try again!');

							handleLogout();
						}

						return config;
					},
					(error) => {
						return Promise.reject(error);
					}
				);

				instance.interceptors.response.use(
					(response) => {
						// Any status code that lies within the range of 2xx causes this function to trigger
						// Do something with response data
						return response;
					},
					(error) => {
						// Any status code that falls outside the range of 2xx causes this function to trigger
						// Do something with response error

						const toastOptions = { id: toastId };

						if (BaseAxios.isAxiosError(error)) {
							let errorMessage = 'Error | ';

							if (error.response) {
								// The request was made and the server responded with a status code that falls out of the range of 2xx!
								const {
									status,
									data: { message, error_id },
								} = error.response as AxiosResponse;

								if (status === 401) {
									// Invalid token
									toast.error(
										'Error | Session expired!',
										toastOptions
									);

									handleLogout();
								} else if (status === 429) {
									toast.error(
										'Too many requests! Try again later.',
										toastOptions
									);
								} else if (status === 403) {
									if (error_id === 'email_not_verified') {
										toast.error(
											'Email not verified!',
											toastOptions
										);
									} else if (error_id === 'account_blocked') {
										toast.error(
											'Account blocked!',
											toastOptions
										);

										navigate('/blocked', { replace: true });
										return;
									} else {
										// Unauthorized access
										toast.error(
											'Error | Unauthorized access!',
											toastOptions
										);
									}

									handleLogout();
								} else if (status === 504) {
									errorMessage +=
										'Please contact the support team.';

									toast.error(errorMessage, toastOptions);
								} else {
									// Status code is 400. Use error_id to differentiate between different commands.
									errorMessage += message;

									if (error_id === 'invalid_customer') {
										toast.error(errorMessage, toastOptions);

										handleLogout();
									}

									toast.error(errorMessage, toastOptions);
								}
							} else if (error.request) {
								// The request was made but no response was received!
								errorMessage += `Server error: Missing response. Error: ${error.message}`;
								toast.error(errorMessage, toastOptions);
							} else {
								errorMessage += error.message;
								toast.error(errorMessage, toastOptions);
							}
						} else {
							console.log(error);

							toast.error(
								'Non-request error | Something went wrong! Try again!',
								toastOptions
							);
						}

						throw new Error(error);
					}
				);

				if (!unmounted) {
					setAxios(Promise.resolve(instance));
				}
			} catch (error) {
				console.log(error);
				console.log('Axios error: Unable to create axios instance.');
			}
		};

		setAxiosInstance();

		return () => {
			unmounted = true;
		};
	}, [
		toastId,
		pathname,
		navigate,
		loginWithRedirect,
		getAccessTokenSilently,
	]);

	return axios;
}

export default useAxios;
