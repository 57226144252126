export const readJsonFile = async (path: string) => {
	try {
		const file = await fetch(path);

		return await file.json();
	} catch (error) {
		console.log(error);

		throw new Error('Unable to read json file!');
	}
};
