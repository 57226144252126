import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect, Fragment } from 'react';

import {
	useAxios,
	Container,
	LoadingBox,
	TextButton,
	ContentSection,
	ContentWrapper,
	TRFComponents,
	useTabsContext,
	DescriptionList,
	DescriptionItem,
	ContentSectionDivider,
	SpecimenInfo,
} from '@pangea-lis-apps/ui';
import { ClinicData, NonClinicData } from '@pangea-lis-apps/utils';

import useViewReport from '../../../utils/hooks/view-report';

/* eslint-disable-next-line */
export interface ViewSampleProps {}

export default function ViewSample(props: ViewSampleProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { dataId } = useParams();
	const { user: auth0_user } = useAuth0();

	const { setRefreshTabs } = useTabsContext();
	const [data, setData] = useState<ClinicData | NonClinicData | undefined>(
		undefined
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!auth0_user || !axios) return;

			try {
				const {
					data: { data },
				} = await (
					await axios
				).get(
					`/api/organization-shared/data/${dataId}/?auth0_user_id=${auth0_user.sub}`
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					console.log(parsedData);

					setData(parsedData);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [axios, auth0_user, dataId]);

	const canViewReport = useViewReport();

	const handleDownloadReport = async () => {
		if (!axios || !auth0_user) return;

		try {
			toast.loading('Downloading...', toastOptions);

			const {
				data: { data },
			} = await (
				await axios
			).get(
				`/api/organization-shared/data/${dataId}/sample/report/?auth0_user_id=${auth0_user.sub}`
			);

			toast.dismiss();
			window.open(data, '_blank');

			setRefreshTabs((value) => !value);
		} catch (error) {
			console.log(error);
		}
	};

	const handleDownloadAttachment = async (aws_object_key: string) => {
		if (!axios) return;

		toast.loading('Downloading...', toastOptions);

		try {
			const {
				data: { data },
			} = await (
				await axios
			).get(
				`/api/organization-shared/data/${dataId}/trf/attachment/?aws_object_key=${encodeURIComponent(
					aws_object_key
				)}`
			);

			toast.dismiss();

			window.open(data, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Container>
			<ContentWrapper Icon="FingerPrintIcon" heading="Specimen Details">
				{!data ? (
					<LoadingBox />
				) : (
					<Fragment>
						<SpecimenInfo data={data} />

						{data.type === 'clinic' && (
							<Fragment>
								<ContentSectionDivider />

								<TRFComponents.PatientInformation data={data} />
							</Fragment>
						)}

						{canViewReport && (
							<Fragment>
								<ContentSectionDivider />

								<ContentSection heading="Report">
									<DescriptionList>
										<DescriptionItem term="Download Link">
											{data.sample.report
												.aws_object_key ? (
												<TextButton
													color="blue"
													type="button"
													text="Download"
													onClick={
														handleDownloadReport
													}
												/>
											) : (
												'Not available'
											)}
										</DescriptionItem>
									</DescriptionList>
								</ContentSection>
							</Fragment>
						)}

						{data.type === 'clinic' && (
							<Fragment>
								<ContentSectionDivider />

								<ContentSection heading="Attachments">
									<DescriptionList>
										<DescriptionItem term="Requisition Form">
											{data.requisition_form
												.attachments ? (
												<ul className="list-inside">
													{data.requisition_form.attachments.map(
														(
															attachment: {
																aws_object_key: string;
																file_name: string;
															},
															index: number
														) => {
															return (
																<li key={index}>
																	<TextButton
																		text={
																			attachment.file_name
																		}
																		color="blue"
																		type="button"
																		onClick={() => {
																			handleDownloadAttachment(
																				attachment.aws_object_key
																			);
																		}}
																	/>
																</li>
															);
														}
													)}
												</ul>
											) : (
												<p>Not available</p>
											)}
										</DescriptionItem>
									</DescriptionList>
								</ContentSection>
							</Fragment>
						)}
					</Fragment>
				)}
			</ContentWrapper>
		</Container>
	);
}
