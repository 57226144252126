import { useAuth0 } from '@auth0/auth0-react';

import { Button, PublicPageWrapper } from '@pangea-lis-apps/ui';

export interface IndexProps {}

export function Index(props: IndexProps) {
	const { loginWithRedirect } = useAuth0();
	const ASSOCIATE_PORTAL = process.env['NX_PORTAL_TYPE'] === 'ASSOCIATE';

	return (
		<PublicPageWrapper
			heading={process.env['NX_SITE_NAME']!}
			subheading={`${
				ASSOCIATE_PORTAL ? 'For internal employees only' : 'For clients'
			}`}
			description={
				ASSOCIATE_PORTAL
					? 'For managing samples through the lab process, from accessioning all the way to reporting'
					: 'View specimens, patients, and test results'
			}
		>
			<Button
				text="Login"
				type="button"
				tier="primary"
				onClick={() =>
					loginWithRedirect({
						redirectUri:
							window.location.origin.concat('/callback/'),
					})
				}
			/>
		</PublicPageWrapper>
	);
}

export default Index;
