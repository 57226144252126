import { RefObject, forwardRef, ReactNode, FormEventHandler } from 'react';

interface FormProps {
	id?: string;
	className?: string;
	children: ReactNode;
	ref: RefObject<HTMLFormElement>;
	handleSubmit?: FormEventHandler<HTMLFormElement> | undefined;
}

export const Form = forwardRef<HTMLFormElement, FormProps>((props, ref) => {
	return (
		<form
			ref={ref}
			id={props.id}
			onSubmit={props.handleSubmit}
			className={`space-y-8 ${props.className && props.className}`}
		>
			{props.children}
		</form>
	);
});
