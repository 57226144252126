import { Ref, ReactNode, KeyboardEvent, ChangeEventHandler } from 'react';

import { Label } from '../label/label';

export interface GenericFieldProps {
	name: string;
	type: string;
	required?: boolean;
	maxLength?: number;
	disabled?: boolean;
	autoFocus?: boolean;
	placeholder?: string;
	value: string | number;
	fieldAction?: ReactNode;
	label?: string | ReactNode;
	showRequiredAsterisk?: boolean;
	inputFieldRef?: Ref<HTMLInputElement> | undefined;
	handleKeyDown?: (
		event: KeyboardEvent<HTMLInputElement>
	) => void | undefined;
	handleInputChange: ChangeEventHandler<HTMLInputElement> | undefined;
}

export function GenericField({
	name,
	type,
	required,
	maxLength,
	disabled,
	autoFocus,
	placeholder,
	value,
	fieldAction,
	label,
	showRequiredAsterisk,
	inputFieldRef,
	handleKeyDown,
	handleInputChange,
}: GenericFieldProps) {
	return (
		<div>
			{label && (
				<div className="flex items-center justify-between">
					<Label
						name={name}
						label={label}
						required={required || showRequiredAsterisk}
					/>
					{fieldAction && fieldAction}
				</div>
			)}
			<input
				id={name}
				name={name}
				type={type} // text, email
				value={value}
				disabled={disabled}
				ref={inputFieldRef}
				required={required !== null ? required : false}
				autoFocus={autoFocus}
				maxLength={maxLength}
				onKeyDown={handleKeyDown}
				placeholder={placeholder}
				onChange={handleInputChange}
				className="mt-1 block w-full shadow-sm sm:text-sm rounded-md focus:ring-2 focus:ring-blue-200 focus:border-blue-500 border-gray-300 placeholder:italic placeholder:text-gray-400 disabled:bg-gray-100 disabled:text-gray-400 disabled:font-medium disabled:hover:cursor-not-allowed"
			/>
		</div>
	);
}

export default GenericField;
