/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactNode } from 'react';

import {
	TabsProvider,
	LoadingProvider,
	Auth0ProviderWithHistory,
} from '@pangea-lis-apps/ui';

import scopes from './scope.json';

interface ContextProvidersProps {
	children: ReactNode;
}

export default function ContextProviders(props: ContextProvidersProps) {
	return (
		<Auth0ProviderWithHistory
			domain={process.env['NX_AUTH0_APP_DEFAULT_DOMAIN']!}
			audience={process.env['NX_AUTH0_APP_AUDIENCE']!}
			clientId={process.env['NX_AUTH0_APP_CLIENT_ID']!}
			scope={`openid profile email ${scopes.permissions.join(' ')}`}
		>
			<TabsProvider>
				<LoadingProvider>{props.children}</LoadingProvider>
			</TabsProvider>
		</Auth0ProviderWithHistory>
	);
}
