import { ReactNode } from 'react';

export interface FormFooterProps {
	className?: string;
	children: ReactNode;
}

export function FormFooter(props: FormFooterProps) {
	return (
		<div
			className={`border-t border-gray-200 pt-3 flex items-center justify-end mt-8 space-x-2 ${
				props.className && props.className
			}`}
		>
			{props.children}
		</div>
	);
}

export default FormFooter;
