import { Option } from '@pangea-lis-apps/utils';
import { ChangeEventHandler } from 'react';

import { Label } from '../label/label';

export interface MultipleSelectFieldProps {
	name: string;
	label: string;
	value: string[];
	options: Option[];
	required?: boolean;
	customSize?: number;
	handleSelect: ChangeEventHandler<HTMLSelectElement>;
}

export function MultipleSelectField(props: MultipleSelectFieldProps) {
	return (
		<div>
			<Label
				name={props.name}
				label={props.label}
				required={props.required}
				description="To select multiple options, Ctrl/Cmd + Click an option."
			/>
			<select
				multiple={true}
				id={props.name}
				name={props.name}
				value={props.value}
				required={props.required}
				onChange={props.handleSelect}
				size={props.customSize ? props.customSize : 4}
				className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md mt-2 overflow-x-auto"
			>
				{props.options.map((item, index) => (
					<option
						key={index}
						value={item.value}
						disabled={item.disabled || item.value === ''}
						className="p-2 my-1 rounded-md hover:enabled:bg-blue-100 hover:enabled:text-blue-900 checked:bg-blue-100 checked:text-blue-900 checked:font-medium"
					>
						{item.label}
					</option>
				))}
			</select>
		</div>
	);
}

export default MultipleSelectField;
