import {
	Ref,
	ReactNode,
	RefObject,
	MouseEventHandler,
	ChangeEventHandler,
	KeyboardEventHandler,
} from 'react';
import { Disclosure } from '@headlessui/react';
import {
	ChevronUpIcon,
	MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';

import {
	Form,
	Button,
	FormFooter,
	TextButton,
	ContentSection,
} from '@pangea-lis-apps/ui';

interface InputFieldProps {
	value: string;
	placeholderText: string;
	inputFieldRef?: Ref<HTMLInputElement> | undefined;
	handleKeyDown: KeyboardEventHandler<HTMLInputElement>;
	handleInputChange: ChangeEventHandler<HTMLInputElement> | undefined;
}

export function SearchInputField({
	value,
	inputFieldRef,
	handleKeyDown,
	placeholderText,
	handleInputChange,
}: InputFieldProps) {
	return (
		<div className="flex items-center space-x-4">
			<div className="relative">
				<div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
					<MagnifyingGlassIcon
						aria-hidden="true"
						className="h-5 w-5 text-gray-500"
					/>
				</div>
				<input
					type="text"
					value={value}
					name="specimen_id"
					ref={inputFieldRef}
					onKeyDown={handleKeyDown}
					onChange={handleInputChange}
					placeholder={placeholderText}
					className="block w-full pl-12 py-2 pr-14 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
				/>
			</div>
		</div>
	);
}

interface SelectInputFieldProps {
	name: string;
	options: {
		label: string;
		value: string | number;
	}[];
	defaultValue: string;
	selectedValue: string;
	handleSelect: ChangeEventHandler<HTMLSelectElement> | undefined;
}

export function FilterSelectInputField({
	name,
	options,
	defaultValue,
	handleSelect,
	selectedValue,
}: SelectInputFieldProps) {
	return (
		<select
			id={name}
			name={name}
			onChange={handleSelect}
			defaultValue={defaultValue}
			className="pl-4 pr-14 rounded-lg border border-gray-300 font-medium text-gray-700 shadow-sm cursor-pointer focus:ring focus:ring-blue-200"
		>
			{options.map((item, index) => (
				<option
					key={index}
					value={item.value}
					selected={item.value === selectedValue}
				>
					{item.label}
				</option>
			))}
		</select>
	);
}

interface ActionButtonProps {
	label: string;
	value: string;
	active: boolean;
	handleActionButtonClick: (action: string) => void;
}

export const ActionButton = (props: ActionButtonProps) => (
	<div
		className={`rounded-full font-medium px-6 py-2 cursor-pointer ${
			props.active
				? 'bg-light-blue border-2 border-blue'
				: 'bg-gray-50 border-2'
		}`}
		onClick={() => props.handleActionButtonClick(props.value)}
	>
		<p className={`${props.active && 'text-blue'}`}>{props.label}</p>
	</div>
);

interface SearchFiltersFormProps {
	heading?: string;
	disabled: boolean;
	children: ReactNode;
	description?: string;
	clearFilters: () => void;
	formRef?: RefObject<HTMLButtonElement>;
	handleSubmit: MouseEventHandler<HTMLButtonElement>;
}

export const SearchFiltersForm = (props: SearchFiltersFormProps) => {
	return (
		<ContentSection>
			<Disclosure defaultOpen={true}>
				{({ open }) => (
					<>
						<Disclosure.Button
							className={`${
								open
									? 'rounded-t-md border-l border-r border-t'
									: 'rounded-md border'
							} bg-gray-100 w-full text-left text-sm border-gray-200 px-4 py-2 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75`}
						>
							<div className="flex justify-between w-full">
								<p className="font-medium text-gray-900">
									{props.heading
										? props.heading
										: 'Apply Search Filters'}
								</p>
								<ChevronUpIcon
									className={`${
										open ? 'rotate-180 transform' : ''
									} h-5 w-5 text-gray-500`}
								/>
							</div>
							{props.description && (
								<p className="mt-1 text-xs">
									{props.description}
								</p>
							)}
						</Disclosure.Button>
						<Disclosure.Panel>
							<ContentSection className="border rounded-b-lg border-gray-200 shadow-sm bg-gray-100 py-4 px-4 sm:!px-4 lg:!px-4">
								<Form>
									{props.children}

									<FormFooter className="!justify-between">
										<TextButton
											color="gray"
											type="button"
											text="Clear All Filters"
											className="group-hover:text-gray-700"
											onClick={() => props.clearFilters()}
										/>
										<Button
											text="Apply"
											type="button"
											tier="tertiary"
											Icon="CheckIcon"
											ref={props.formRef}
											disabled={props.disabled}
											onClick={props.handleSubmit}
										/>
									</FormFooter>
								</Form>
							</ContentSection>
						</Disclosure.Panel>
					</>
				)}
			</Disclosure>
		</ContentSection>
	);
};
