import { useAuth0 } from '@auth0/auth0-react';

import { PublicPageWrapper, Button } from '@pangea-lis-apps/ui';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BlockedProps {}

export function Blocked(props: BlockedProps) {
	const { logout } = useAuth0();

	return (
		<PublicPageWrapper
			heading="Account blocked"
			subheading="Authorization"
			description="If you believe this is an error, please contact clientservices@pangealab.com."
		>
			<Button
				text="Logout"
				type="button"
				tier="primary"
				onClick={() => logout({ returnTo: window.location.origin })}
			/>
		</PublicPageWrapper>
	);
}

export default Blocked;
