import * as Heroicons from '@heroicons/react/24/solid';
import { Link as ReactRouterDomLink } from 'react-router-dom';

import { IconName, classNames } from '@pangea-lis-apps/utils';

/* eslint-disable-next-line */
export interface LinkProps {
	path: string;
	text: string;
	onClick?: () => void;
	className?: string;
}

export function Link(props: LinkProps) {
	return (
		<ReactRouterDomLink
			to={props.path}
			onClick={props.onClick}
			className={`text-blue-600 hover:text-blue-900 font-medium ${
				props.className && props.className
			}`}
		>
			{props.text}
		</ReactRouterDomLink>
	);
}

interface ButtonLinkProps extends LinkProps {
	Icon?: IconName;
	tier: 'primary' | 'secondary' | 'tertiary' | 'error';
}

export const LinkButton = (props: ButtonLinkProps) => {
	const Icon = Heroicons[props.Icon || 'ExclamationCircleIcon'];

	// Set button styles
	let buttonStyles = '';

	if (props.tier === 'primary')
		buttonStyles =
			'text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-200';
	else if (props.tier === 'secondary')
		buttonStyles =
			'text-blue-600 bg-blue-50 border border-blue-200 hover:bg-blue-100 focus:ring-blue-200';
	else if (props.tier === 'tertiary')
		buttonStyles =
			'text-gray-600 bg-gray-50 border border-gray-200 hover:bg-gray-100 focus:ring-gray-200';
	else
		buttonStyles =
			'text-white bg-orange-600 hover:bg-orange-700 focus:ring-orange-200';

	return (
		<ReactRouterDomLink
			to={props.path}
			onClick={props.onClick}
			className={classNames(
				buttonStyles,
				'inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md shadow-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed focus:outline-none focus:ring-2'
			)}
		>
			{props.Icon && (
				<Icon aria-hidden="true" className="-ml-1 mr-2 h-4 w-4" />
			)}
			{props.text}
		</ReactRouterDomLink>
	);
};

export default Link;
