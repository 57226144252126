import { ReactNode, useRef, Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { classNames } from '@pangea-lis-apps/utils';

interface ModalProps {
	title: string;
	visible: boolean;
	children: ReactNode;
	onClose: () => void;
	customWidth?: string;
	description?: ReactNode;
	hideCloseButton?: boolean;
}

export function Modal(props: ModalProps) {
	const dialogButtonRef = useRef<HTMLButtonElement>(null);

	return (
		<Transition.Root appear as={Fragment} show={props.visible}>
			<Dialog
				as="div"
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onClose={() => {}}
				// onClose={props.onClose}
				className="relative z-10"
				initialFocus={dialogButtonRef}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel
							className={classNames(
								'mx-auto transform rounded-md bg-white border border-gray-300 shadow-2xl transition-all p-6',
								props.customWidth
									? props.customWidth
									: 'max-w-lg'
							)}
						>
							<div className="flex items-start justify-between">
								<div>
									<Dialog.Title className="text-base leading-6 font-medium text-gray-900">
										{props.title}
									</Dialog.Title>
									{props.description && (
										<Dialog.Description className="mt-1 max-w-xl text-sm text-gray-500">
											{props.description}
										</Dialog.Description>
									)}
								</div>
								{!props.hideCloseButton && (
									<div className="ml-3 flex h-7 items-center">
										<button
											type="button"
											onClick={() => props.onClose()}
											className="rounded-sm bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
										>
											<span className="sr-only">
												Close panel
											</span>
											<XMarkIcon
												className="h-6 w-6"
												aria-hidden="true"
											/>
										</button>
									</div>
								)}
							</div>

							<hr className="mt-4" />

							<div className="mt-4">{props.children}</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
