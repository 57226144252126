import { ReactNode } from 'react';

export interface HeaderCellProps {
	styles?: string;
	customWidth?: string;
	children: string | ReactNode;
}

export function HeaderCell(props: HeaderCellProps) {
	return (
		<th
			scope="col"
			className={`${
				props.customWidth ? props.customWidth : 'w-52'
			} p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
				props.styles
			}`}
		>
			{props.children}
		</th>
	);
}

export default HeaderCell;
