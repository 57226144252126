/**
 * Filename: data-components.ts
 * Description: This file contains functions and types used in the DescriptionItem components in the TRFComponents for each assay.
 */

import { ReactNode } from 'react';

import { Date } from '../common-types';
import { classNames } from '../classnames';
import { ClinicData, NonClinicData } from './types/data';

// Types
export interface FieldProps {
	hide?: string[];
	headingAction?: ReactNode;
	data: ClinicData | NonClinicData;
}

export interface MiscAndAttachmentsFieldProps extends FieldProps {
	entity: 'accessioner' | 'client-services' | 'organization-shared' | 'cls';
}

/**
 * This function is used for the termStyles for DescriptionItem components in the TRFComponents for each assay.
 * This function should only be applied to required fields.
 * If a value is not provided, then this will return styles for highlighting the DescriptionItem.
 *
 * @param {number} value - The value of the DescriptionItem.
 * @returns {string} Styles for highlighting the DescriptionItem.
 */
export const requiredStyles = (
	value: string | Date | boolean | any[] | null
): string => {
	let hasValue = false;

	if (!value) hasValue = false;
	else if (typeof value === 'boolean') hasValue = true;
	else if (typeof value === 'string' && value) hasValue = true;
	else if (Array.isArray(value) && value.length) hasValue = true;
	else if (typeof value === 'object' && '$date' in value && value['$date'])
		hasValue = true;

	return classNames(
		"after:content-['*'] after:text-blue-500 after:ml-0.5",
		!hasValue ? 'bg-blue-100 px-1 rounded-md' : ''
	);
};
