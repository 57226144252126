import { useNavigate } from 'react-router-dom';

import { Button } from '@pangea-lis-apps/ui';

/* eslint-disable-next-line */
export interface PageNotFoundProps {}

export function PageNotFound(props: PageNotFoundProps) {
	const navigate = useNavigate();

	return (
		<div className="w-screen h-screen bg-gray-50 p-20 flex flex-col items-center justify-center">
			<h1 className="text-7xl font-bold mb-4">404</h1>
			<h2 className="text-4xl font-light mb-4">Page not found</h2>
			<p className="text-xl font-light mb-8">
				The page you're looking for does not exist!
			</p>
			<Button
				type="button"
				tier="primary"
				text="Go back"
				onClick={() => navigate(-1)}
			/>
		</div>
	);
}

export default PageNotFound;
