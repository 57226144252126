import { ContentSection } from '@pangea-lis-apps/ui';

interface DividerProps {
	className?: string;
}

export function Divider(props: DividerProps) {
	return <hr className={props.className} />;
}

export function ContentSectionDivider(props: DividerProps) {
	return (
		<ContentSection>
			<Divider className={props.className} />
		</ContentSection>
	);
}
