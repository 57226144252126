import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, AppState } from '@auth0/auth0-react';

interface Auth0ProviderWithHistoryProps {
	scope: string;
	domain: string;
	clientId: string;
	audience: string;
	children: ReactNode;
}

export function Auth0ProviderWithHistory(props: Auth0ProviderWithHistoryProps) {
	const navigate = useNavigate();

	// By default, this function removes the code and state parameters from the url
	// when you are redirected from the authorize page. appState has a type of AppState,
	// which is the state of the application before the user was redirected to the login page.
	const onRedirectCallback = (appState?: AppState): void => {
		navigate(appState?.returnTo || window.location.pathname);
	};

	return (
		<Auth0Provider
			maxAge={3600}
			scope={props.scope}
			domain={props.domain}
			useRefreshTokens={true}
			clientId={props.clientId}
			audience={props.audience}
			cacheLocation="localstorage"
			redirectUri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
		>
			{props.children}
		</Auth0Provider>
	);
}
