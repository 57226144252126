/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, ChangeEvent, SetStateAction } from 'react';

import { DateRange } from '../types/types';

type FormValues = { [key: string]: any };
type DateRangesDisplay = { [key: string]: boolean };

const handleChange = (
	event: ChangeEvent,
	setFormValues: Dispatch<SetStateAction<FormValues>>,
	dateRangeProperties?: string[],
	dateRangesDisplay?: DateRangesDisplay
): void => {
	const target = event.target as HTMLInputElement | HTMLSelectElement;

	if (target && target.name) {
		const dateRange =
			!dateRangeProperties || !dateRangesDisplay
				? false
				: dateRangeProperties.includes(target.name) &&
				  dateRangesDisplay[target.name];

		setFormValues((prevValue) => ({
			...prevValue,
			[target.name]: dateRange
				? {
						...(prevValue[target.name] as DateRange),
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						[target.getAttribute('data-datetype')!]: target.value,
				  }
				: target.value,
		}));
	}
};

const hasAtLeastOneNonNullValue = <T>(formValues: T): boolean => {
	// Check if at least one search criterion was provided.
	for (const key in formValues) {
		const value = formValues[key];

		if (typeof value === 'object') {
			if (
				Object.values(value as Record<string, any>).every(
					(value) => value !== ''
				)
			)
				return true;
		} else if (value) return true;
	}

	return false;
};

type NormalizedFormValues = {
	[key: string]: string | DateRange | undefined | string[];
};

const normalizeFormValues = <T extends NormalizedFormValues>(
	formValues: T
): NormalizedFormValues => {
	// The query should only contain those key-value pairs that have non-null values. Otherwise, the backend will be querying for empty values.
	// NormalizedFormValues prevents warnings about controlled components turning uncontrolled.
	const normalizedFormValues: NormalizedFormValues = {};

	for (const key in formValues) {
		const value = formValues[key];

		if (
			(typeof value === 'object' &&
				Object.values(value).some((value) => value === '')) ||
			!formValues[key]
		)
			continue;

		if (key === 'sample.status') {
			const env = process.env['NX_PORTAL_TYPE']?.toLowerCase();

			if (value == 'flag' && env === 'customer') {
				normalizedFormValues[key] = [
					'flag',
					'needs_review',
					'reviewed',
				];
				continue;
			}
		}

		normalizedFormValues[key] = value;
	}

	return normalizedFormValues;
};

const handleChangeDateDisplay = <T extends NormalizedFormValues>(
	propName: string,
	setDateRangesDisplay: Dispatch<SetStateAction<DateRangesDisplay>>,
	setFormValues: Dispatch<SetStateAction<T>>
): void => {
	// Used for toggling display of range/single-date date display and updating formValues to handle such change.
	let showRange = false;

	setDateRangesDisplay((prevValue) => {
		showRange = !prevValue[propName];

		return {
			...prevValue,
			[propName]: showRange,
		};
	});

	setFormValues((prevValues) => ({
		...prevValues,
		[propName]: showRange ? { date_start: '', date_end: '' } : '',
	}));
};

export const TableSearchFunctions = {
	handleChange,
	normalizeFormValues,
	handleChangeDateDisplay,
	hasAtLeastOneNonNullValue,
};
