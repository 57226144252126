import { useAuth0 } from '@auth0/auth0-react';

import { PublicPageWrapper, Button } from '@pangea-lis-apps/ui';

interface VerifyEmailProps {}

export function VerifyEmail(props: VerifyEmailProps) {
	const { logout } = useAuth0();

	return (
		<PublicPageWrapper
			heading="Verify email"
			subheading="Authorization"
			description="After verifying your email, log out and log back in."
		>
			<Button
				text="Logout"
				type="button"
				tier="primary"
				onClick={() => logout({ returnTo: window.location.origin })}
			/>
		</PublicPageWrapper>
	);
}

export default VerifyEmail;
