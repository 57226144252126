/* eslint-disable @typescript-eslint/no-empty-function */
import {
	Dispatch,
	useState,
	ReactNode,
	useContext,
	createContext,
	SetStateAction,
} from 'react';

export interface LoadingContextType {
	loading: boolean;
	setLoading: Dispatch<SetStateAction<boolean>>;
}

export const LoadingContext = createContext<LoadingContextType>({
	loading: false,
	setLoading: () => {},
});

interface LoadingProviderProps {
	children: ReactNode;
}

export function LoadingProvider({ children }: LoadingProviderProps) {
	const [loading, setLoading] = useState(false);

	return (
		<LoadingContext.Provider
			value={{
				loading,
				setLoading,
			}}
		>
			{children}
		</LoadingContext.Provider>
	);
}

export function useLoadingContext() {
	return useContext(LoadingContext) as LoadingContextType;
}
