/* eslint-disable-next-line */
export interface TableLoadingProps {}

export function TableLoading(props: TableLoadingProps) {
	return (
		<tr className="text-center">
			<td colSpan={100} className="p-4 space-y-2">
				<div className="h-8 bg-gray-100 animate-pulse rounded-md"></div>
				<div className="h-8 bg-gray-100 animate-pulse rounded-md"></div>
				<div className="h-8 bg-gray-100 animate-pulse rounded-md"></div>
				<div className="h-8 bg-gray-100 animate-pulse rounded-md"></div>
			</td>
		</tr>
	);
}

export default TableLoading;
