import * as Heroicons from '@heroicons/react/24/outline';

import { IconName } from '@pangea-lis-apps/utils';

export interface EmptyBoxProps {
	Icon: IconName;
	heading: string;
	description?: string;
}

export function EmptyBox(props: EmptyBoxProps) {
	const Icon = Heroicons[props.Icon || 'ExclamationCircleIcon'];

	return (
		<div className="text-center border border-gray-300 rounded-lg pt-14 px-14 pb-16 bg-gray-50 shadow-sm">
			<Icon className="mx-auto h-10 w-10 text-gray-400 stroke-1" />
			<h3 className="mt-2 text-sm font-medium text-gray-700">
				{props.heading}
			</h3>
			<p className="mt-1 text-sm text-gray-500">{props.description}</p>
		</div>
	);
}

export default EmptyBox;
