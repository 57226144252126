import { ReactNode } from 'react';

export interface DescriptionTermProps {
	children: ReactNode;
	className?: string | null;
}

export function DescriptionTerm(props: DescriptionTermProps) {
	return (
		<dt className={`text-sm font-medium text-gray-500 ${props.className}`}>
			{props.children}
		</dt>
	);
}

export default DescriptionTerm;
