import toast from 'react-hot-toast';
import {
	useId,
	useState,
	useEffect,
	ChangeEvent,
	MouseEventHandler,
} from 'react';

import {
	Link,
	Table,
	DataCell,
	TableRow,
	useAxios,
	TableBody,
	Container,
	FormGroup,
	TableHead,
	DateField,
	HeaderCell,
	EmptyTable,
	GenericField,
	TableLoading,
	ContentWrapper,
	ContentSection,
	SearchFiltersForm,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	Patient,
	formatDate,
	Pagination,
	PaginationState,
	getLabelFromValue,
	getRoleFromPathname,
	TableSearchFunctions,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

// import SelectAssociate from '../../../../shared/components/select-associate';

interface FormValues {
	[key: string]: string | string[] | undefined;
	'customer._id'?: string;
	last_name?: string;
	first_name?: string;
	medical_record_number?: string;
	date_of_birth?: string;
}

const initialFormValues = {
	'customer._id': '',
	last_name: '',
	first_name: '',
	medical_record_number: '',
	date_of_birth: '',
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ViewPatientsProps {}

export default function ViewPatients(props: ViewPatientsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const [disabled, setDisabled] = useState(false);
	const [query, setQuery] = useState<FormValues>({});
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
	const [patients, setPatients] = useState<Patient[] | undefined>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;
			else if (!unmounted) {
				setDisabled(true);
				setPatients(undefined);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/organization-shared/search/patients?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{ query }
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);

					setPatients(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);

			toast.dismiss();
		};

		if (!unmounted) fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [axios, query, pagination.page_number, pagination.entries_per_page]);

	const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (
		event
	) => {
		event.preventDefault();

		if (!TableSearchFunctions.hasAtLeastOneNonNullValue(formValues)) {
			toast.error('Provide a value for at least one filter criterion.');
			return;
		}

		let _query = {};

		if (TableSearchFunctions.hasAtLeastOneNonNullValue(formValues)) {
			const normalizedFormValues =
				TableSearchFunctions.normalizeFormValues(formValues);

			if (formValues['customer._id']) {
				delete normalizedFormValues['customer._id'];

				if (formValues['customer._id'] !== 'all') {
					normalizedFormValues['customers'] = [
						formValues['customer._id'],
					];
				}
			}

			_query = normalizedFormValues;
		}

		setQuery(_query);
		setPagination((prevValue) => ({
			...prevValue,
			page_number: 1,
		}));
	};

	const handleChange = (event: ChangeEvent) =>
		TableSearchFunctions.handleChange(event, setFormValues);

	return (
		<Container>
			<ContentWrapper Icon="UserGroupIcon" heading="All Patients">
				<SearchFiltersForm
					disabled={disabled}
					handleSubmit={handleSubmit}
					clearFilters={() => {
						setQuery({});
						setFormValues(initialFormValues);
						setPagination(initialPaginationValues);
					}}
				>
					{/* <SelectAssociate
						setQuery={setQuery}
						dataCanHaveMultipleOwners={true}
						requiredPermission="manage_patients"
						value={formValues['customer._id'] || ''}
					/> */}

					<FormGroup heading="Patient filters">
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								placeholder="e.g., 0123456789"
								label="Medical Record Number (MRN)"
								handleInputChange={handleChange}
								name="medical_record_number"
								value={formValues.medical_record_number || ''}
							/>
						</div>
						<div className="sm:col-span-4"></div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="First Name"
								placeholder="e.g., John"
								handleInputChange={handleChange}
								name="first_name"
								value={formValues.first_name || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<GenericField
								type="text"
								label="Last Name"
								placeholder="e.g., Kim"
								handleInputChange={handleChange}
								name="last_name"
								value={formValues.last_name || ''}
							/>
						</div>
						<div className="sm:col-span-2">
							<DateField
								label="Date of Birth"
								name="date_of_birth"
								handleInputChange={handleChange}
								value={formValues.date_of_birth || ''}
							/>
						</div>
					</FormGroup>
				</SearchFiltersForm>

				<ContentSectionDivider />

				<ContentSection heading="All patients">
					<AllPatientsTable
						data={patients}
						pagination={{
							pagination,
							setPagination,
						}}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}

interface AllPatientsTableProps {
	data: Patient[] | undefined;
	pagination: PaginationState;
}

export function AllPatientsTable(props: AllPatientsTableProps) {
	const role = getRoleFromPathname();

	return (
		<Table pagination={props.pagination}>
			<TableHead>
				<HeaderCell>First Name</HeaderCell>
				<HeaderCell>Middle Name</HeaderCell>
				<HeaderCell>Last Name</HeaderCell>
				<HeaderCell>Date of Birth</HeaderCell>
				<HeaderCell>Address</HeaderCell>
				<HeaderCell>Phone Number</HeaderCell>
				<HeaderCell>Medical Record Number (MRN)</HeaderCell>
				<HeaderCell>Actions</HeaderCell>
			</TableHead>
			<TableBody>
				{props.data ? (
					props.data.length ? (
						props.data.map((datum, index) => (
							<TableRow key={index} index={index}>
								<DataCell>{datum.first_name}</DataCell>
								<DataCell>{datum.middle_name}</DataCell>
								<DataCell>{datum.last_name}</DataCell>
								<DataCell>
									{formatDate(datum.date_of_birth)}
								</DataCell>
								<DataCell>
									<span>{datum.address}</span>
									<br />
									<span>
										{datum.city},{' '}
										{getLabelFromValue(
											datum.state,
											FORMS.states
										)}{' '}
										{datum.zip_code}
										<br />
										{getLabelFromValue(
											datum.country,
											FORMS.countries
										)}
									</span>
								</DataCell>
								<DataCell>{datum.phone_number}</DataCell>
								<DataCell>
									{datum.medical_record_number}
								</DataCell>
								<DataCell>
									<Link
										text="View"
										path={`/${role}/patients/${datum._id.$oid}`}
									/>
								</DataCell>
							</TableRow>
						))
					) : (
						<EmptyTable
							Icon="UserIcon"
							heading="Search for the patient"
							description="Use the filters provided above"
						/>
					)
				) : (
					<TableLoading />
				)}
			</TableBody>
		</Table>
	);
}
