import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect, MouseEventHandler } from 'react';

import {
	Button,
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	Pagination,
	NonClinicData,
	TableSearchFunctions,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import ViewSamplesTable, {
	ViewSamplesProjectedFields,
} from '../../components/view-samples-table';
import TableSearchFiltersForm from '../../components/table-search-filters-form';

/* eslint-disable-next-line */
export interface SearchReportsProps {}

export default function SearchReports(props: SearchReportsProps) {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const { user } = useAuth0();

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<NonClinicData[] | undefined>([]);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !user) return;
			else if (!unmounted) {
				setDisabled(true);
				setData(undefined);
			}

			toast.loading('Loading...');

			try {
				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/organization-shared/search/reports?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							...query,
							'sample.status': ['verified', 'reject'],
							'sample.report.generated.value': true,
						},
						projected_fields: ViewSamplesProjectedFields,
					}
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					// console.log(parsedData, total_entries);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);
			toast.dismiss();
		};

		if (!unmounted) fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [
		user,
		axios,
		query,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	// Download results as CSV
	const [downloadDisabled, setDownloadDisabled] = useState(false);

	const handleDownloadResultsAsExcel: MouseEventHandler = async (event) => {
		event.preventDefault();

		if (disabled || !axios || !user) return;

		setDownloadDisabled(true);

		toast.loading('Downloading...', toastOptions);

		let query = {};

		if (TableSearchFunctions.hasAtLeastOneNonNullValue(query)) {
			query = TableSearchFunctions.normalizeFormValues(query);
		}

		try {
			const { data } = await (
				await axios
			).post(
				`/api/organization-shared/results/download/?auth0_user_id=${user.sub}`,
				{
					...query,
					'sample.status': 'verified',
					'sample.report.verified.value': true,
					'sample.report.generated.value': true,
				},
				{
					responseType: 'arraybuffer',
				}
			);

			toast.dismiss();

			const blob = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Results.xlsx`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);

			setDownloadDisabled(false);
		} catch (error) {
			console.log(error);
			setDownloadDisabled(false);
		}
	};

	return (
		<Container>
			<ContentWrapper
				heading="Search Reports"
				Icon="DocumentChartBarIcon"
				description="Search all completed test reports."
			>
				<TableSearchFiltersForm
					tableType="report"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection
					heading="All reports"
					headingAction={
						data &&
						Boolean(data.length) && (
							<Button
								type="submit"
								tier="tertiary"
								text="Download results"
								Icon="ArrowDownCircleIcon"
								disabled={downloadDisabled}
								onClick={handleDownloadResultsAsExcel}
							/>
						)
					}
				>
					<ViewSamplesTable
						data={data}
						tableType="report"
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
