import { ReactNode } from 'react';

interface ContainerProps {
	children: ReactNode;
	className?: string;
	withoutConstrainedContentArea?: boolean;
}

export const Container = (props: ContainerProps) => {
	return (
		<main
			className={`relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last bg-slate-50/30 ${
				props.className && props.className
			}`}
		>
			{props.withoutConstrainedContentArea ? (
				props.children
			) : (
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					{props.children}
				</div>
			)}
		</main>
	);
};
