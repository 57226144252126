/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Fragment, useState } from 'react';

import {
	Badge,
	ContentSection,
	DescriptionList,
	DescriptionItem,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	FieldProps,
	capitalize,
	formatDate,
	ORGANIZATIONS,
	getSpecimenTypes,
	getSampleStatuses,
	getLabelFromValue,
	getAllRequisitionFormOptions,
} from '@pangea-lis-apps/utils';

import PrintBarcodeModal from '../../components/label-printer/print-barcode-modal';

export function SpecimenInfo(props: FieldProps) {
	const isAssociatePortal = process.env['NX_PORTAL_TYPE'] === 'ASSOCIATE';

	if (isAssociatePortal) {
		return <InternalSpecimenInfo {...props} />;
	}

	return <ClientSpecimenInfo {...props} />;
}

function InternalSpecimenInfo({ data }: FieldProps) {
	const [labelPrinterModalVisible, setLabelPrinterModalVisible] =
		useState<boolean>(false);
	return (
		<Fragment>
			<ContentSection heading="Specimen info">
				<DescriptionList>
					<DescriptionItem
						term="Status"
						details={getLabelFromValue(
							data.sample.status,
							getSampleStatuses()
						)}
					/>
					<DescriptionItem
						term="Priority Level"
						details={getLabelFromValue(
							data.sample.priority,
							ORGANIZATIONS.priorityLevels
						)}
					/>
					<DescriptionItem term="Is RUO?" details={data.sample.ruo} />
					<DescriptionItem
						term="Assay"
						details={getLabelFromValue(
							data.sample.assay,
							ASSAYS['assays']
						)}
					/>
					<DescriptionItem
						term="Specimen Type"
						details={getLabelFromValue(
							data.sample.sample_specimen_type,
							getSpecimenTypes()
						)}
					/>
					<div className="sm:col-span-1"></div>
					{['needs_review', 'reviewed'].includes(
						data.sample.status
					) && (
						<Fragment>
							<DescriptionItem
								term="Review decision"
								details={
									data.sample.accessioning_approval.reviewed
										.reject === null
										? null
										: data.sample.accessioning_approval
												.reviewed.reject
										? 'Rejected'
										: 'Approved'
								}
							/>
							<DescriptionItem
								term="Request to review reason"
								details={
									data.sample.accessioning_approval
										.needs_review.reason.value
								}
							/>
							<div className="sm:col-span-1"></div>
						</Fragment>
					)}
					{data.sample.status === 'flag' && (
						<Fragment>
							<DescriptionItem
								term="Flag Reason"
								details={
									data.sample.accessioning_approval.flag
										.reason.value
								}
							/>
							<DescriptionItem
								term="Well location"
								details={
									data.sample.accessioning_approval.flag
										.selected_well.rack_name &&
									data.sample.accessioning_approval.flag
										.selected_well.location &&
									`Rack ${
										data.sample.accessioning_approval.flag
											.selected_well.rack_name || '-'
									}, Well ${
										data.sample.accessioning_approval.flag
											.selected_well.location || '-'
									}`
								}
							/>
							<div className="sm:col-span-1"></div>
						</Fragment>
					)}
					{data.type === 'clinic' && data.sample.status === 'hold' && (
						<Fragment>
							<DescriptionItem
								term="Hold Reason"
								details={
									data.sample.accessioning_approval.hold
										.reason.value
								}
							/>
							<DescriptionItem
								term="Well location"
								details={
									data.sample.accessioning_approval.hold
										.selected_well.rack_name &&
									data.sample.accessioning_approval.hold
										.selected_well.location &&
									`Rack ${
										data.sample.accessioning_approval.hold
											.selected_well.rack_name || '-'
									}, Well ${
										data.sample.accessioning_approval.hold
											.selected_well.location || '-'
									}`
								}
							/>
							<div className="sm:col-span-1"></div>
						</Fragment>
					)}
					{data.sample.status === 'reject' && (
						<DescriptionItem
							term="Rejection reason"
							customColSpan="sm:col-span-3"
							details={
								data.sample.accessioning_approval.reject.reason
									.value
							}
						/>
					)}
					<DescriptionItem
						term="Specimen ID"
						details={data.sample.specimen_id}
					/>
					<DescriptionItem
						term="Pangea ID"
						details={data.sample.pangea_id}
						Icon="PrinterIcon"
						withFunction={true}
						handleClick={() => setLabelPrinterModalVisible(true)}
					/>
					<DescriptionItem
						term="Collection Kit ID"
						details={data.sample.collection_kit_id}
					/>
					<DescriptionItem
						term="Collection Date"
						details={formatDate(data.sample.sample_collection_date)}
					/>
					<DescriptionItem
						term="Received Date"
						details={formatDate(data.sample.sample_received_date)}
					/>
					<div className="sm:col-span-1"></div>
					{data.sample.assay === 'resp4plex' && (
						<Fragment>
							<DescriptionItem
								term="Specimen Transport Conditions"
								details={getLabelFromValue(
									data.sample
										.sample_transport_storage_condition,
									Object.values(
										getAllRequisitionFormOptions(
											'transport_storage_conditions',
											'array'
										)
									)
								)}
							/>
							<div className="sm:col-span-2"></div>
						</Fragment>
					)}
					<DescriptionItem
						term="Transport By?"
						details={formatDate(
							data.sample.misc.transport_time_requirement
								.transport_by &&
								data.sample.misc.transport_time_requirement
									.transport_by.$date
						)}
					/>
					<DescriptionItem term="Transport Time Met?">
						{data.sample.misc.transport_time_requirement
							.transport_time_met !== null ? (
							data.sample.misc.transport_time_requirement
								.transport_time_met ? (
								<Badge content="Yes" color="green" />
							) : (
								<Badge content="No" color="red" />
							)
						) : null}
					</DescriptionItem>
					<DescriptionItem
						term="Tracking Number"
						details={data.sample.tracking_number}
					/>
					<DescriptionItem
						term="Stable Until?"
						details={formatDate(
							data.sample.misc.sample_stability.stable_until &&
								data.sample.misc.sample_stability.stable_until
									.$date
						)}
					/>
					<DescriptionItem term="Stable?">
						{data.sample.misc.sample_stability.stable !== null ? (
							data.sample.misc.sample_stability.stable ? (
								<Badge content="Yes" color="green" />
							) : (
								<Badge content="No" color="red" />
							)
						) : null}
					</DescriptionItem>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="Turnaround by?"
						details={formatDate(
							data.sample.misc.turnaround.by &&
								data.sample.misc.turnaround.by.$date
						)}
					/>
					<DescriptionItem
						term="Turnaround Date"
						details={formatDate(
							data.sample.misc.turnaround.date &&
								data.sample.misc.turnaround.date.$date
						)}
					/>
					<DescriptionItem term="Turnaround Met?">
						{data.sample.misc.turnaround.met !== null ? (
							data.sample.misc.turnaround.met ? (
								<Badge content="Yes" color="green" />
							) : (
								<Badge content="No" color="red" />
							)
						) : null}
					</DescriptionItem>
				</DescriptionList>

				{Object.keys(data.sample.misc.added_fields).length > 0 && (
					<DescriptionList
						className="mt-8"
						heading="Additional fields"
						description="Supplementary information provided by the organization that aren't required for accessioning or processing the sample."
					>
						{Object.keys(data.sample.misc.added_fields).map(
							(key: string) => {
								const value =
									data.sample.misc.added_fields[key];

								return (
									<DescriptionItem
										key={key}
										term={capitalize(
											key.replace(/_/g, ' ')
										)}
										details={
											key.includes('date')
												? formatDate(value)
												: value
										}
									/>
								);
							}
						)}
					</DescriptionList>
				)}
			</ContentSection>
			<PrintBarcodeModal
				data={data}
				visible={{
					visible: labelPrinterModalVisible,
					setVisible: setLabelPrinterModalVisible,
				}}
			/>
		</Fragment>
	);
}

function ClientSpecimenInfo({ data }: FieldProps) {
	return (
		<ContentSection heading="Specimen info">
			<DescriptionList>
				<DescriptionItem
					term="Status"
					details={getLabelFromValue(
						data.sample.status,
						getSampleStatuses()
					)}
				/>
				{data.sample.priority === 'stat' ? (
					<Fragment>
						<DescriptionItem
							term="Priority Level"
							details={getLabelFromValue(
								data.sample.priority,
								ORGANIZATIONS.priorityLevels
							)}
						/>
						<div className="sm:col-span-1"></div>
					</Fragment>
				) : (
					<div className="sm:col-span-2"></div>
				)}
				{data.sample.status === 'flag' && (
					<Fragment>
						<DescriptionItem
							term="On-Hold Reason"
							details={
								data.sample.accessioning_approval.flag.reason
									.value
							}
						/>
						<div className="sm:col-span-2"></div>
					</Fragment>
				)}
				{data.type === 'clinic' && data.sample.status === 'hold' && (
					<Fragment>
						<DescriptionItem
							term="On-Hold Reason"
							details={
								data.sample.accessioning_approval.hold.reason
									.value
							}
						/>
						<div className="sm:col-span-2"></div>
					</Fragment>
				)}
				{data.sample.status === 'reject' && (
					<DescriptionItem
						term="Rejection reason"
						customColSpan="sm:col-span-3"
						details={
							data.sample.accessioning_approval.reject.reason
								.value
						}
					/>
				)}
				<DescriptionItem
					term="Assay"
					details={getLabelFromValue(
						data.sample.assay,
						ASSAYS['assays']
					)}
				/>
				<DescriptionItem
					term="Specimen ID"
					details={data.sample.specimen_id}
				/>
				<DescriptionItem
					term="Pangea ID"
					details={data.sample.pangea_id}
				/>
				<DescriptionItem
					term="Collection Kit ID"
					details={data.sample.collection_kit_id}
				/>
				<DescriptionItem
					term="Collection Date"
					details={formatDate(data.sample.sample_collection_date)}
				/>
				<DescriptionItem
					term="Received Date"
					details={formatDate(data.sample.sample_received_date)}
				/>
			</DescriptionList>

			{Object.keys(data.sample.misc.added_fields).length > 0 && (
				<DescriptionList
					className="mt-8"
					heading="Additional fields"
					description="Supplementary information provided with the speicmen."
				>
					{Object.keys(data.sample.misc.added_fields).map(
						(key: string) => {
							const value = data.sample.misc.added_fields[key];

							return (
								<DescriptionItem
									key={key}
									term={capitalize(key.replace(/_/g, ' '))}
									details={
										key.includes('date')
											? formatDate(value)
											: value
									}
								/>
							);
						}
					)}
				</DescriptionList>
			)}
		</ContentSection>
	);
}

export default SpecimenInfo;
