import { ReactNode } from 'react';
import {
	InformationCircleIcon,
	ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';

export interface AlertProps {
	heading: string;
	className?: string;
	children?: ReactNode;
	description?: ReactNode;
	type: 'info' | 'warning';
}

export function Alert(props: AlertProps) {
	const styles = (() => {
		switch (props.type) {
			case 'info':
				return {
					divStyles: 'bg-blue-50 border-blue-300',
					icon: InformationCircleIcon,
					iconStyles: 'text-blue-400',
					headingStyles: 'text-blue-800',
					paragraphStyles: 'text-blue-700',
				};
			case 'warning':
				return {
					divStyles: 'bg-yellow-50 border-yellow-300',
					icon: ExclamationTriangleIcon,
					iconStyles: 'text-yellow-400',
					headingStyles: 'text-yellow-800',
					paragraphStyles: 'text-yellow-700',
				};
			default:
				return {
					divStyles: 'bg-yellow-50 border-yellow-300',
					icon: ExclamationTriangleIcon,
					iconStyles: 'text-yellow-400',
					headingStyles: 'text-yellow-800',
					paragraphStyles: 'text-yellow-700',
				};
		}
	})();

	return (
		<div
			className={`rounded-md border pl-4 pt-4 pb-4 pr-6 ${styles.divStyles} ${props.className}`}
		>
			<div className="flex">
				<div className="flex-shrink-0">
					<styles.icon
						aria-hidden="true"
						className={`h-5 w-5 ${styles.iconStyles}`}
					/>
				</div>
				<div className="ml-3">
					<h3
						className={`"text-sm font-medium leading-tight ${styles.headingStyles}`}
					>
						{props.heading}
					</h3>
					{props.description && (
						<div className="mt-1">
							<p className={`text-sm ${styles.paragraphStyles}`}>
								{props.description}
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Alert;
