import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect } from 'react';

import {
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	Pagination,
	NonClinicData,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

import ViewSamplesTable, {
	ViewSamplesProjectedFields,
} from '../../components/view-samples-table';
import TableSearchFiltersForm from '../../components/table-search-filters-form';

/* eslint-disable-next-line */
export interface ViewAllSamplesProps {}

export default function ViewAllSamples(props: ViewAllSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<NonClinicData[] | undefined>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				if (!unmounted) {
					setDisabled(true);
					setData(undefined);
				}

				toast.loading('Loading...');

				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/organization-shared/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{ query, projected_fields: ViewSamplesProjectedFields }
				);

				if (!unmounted) {
					const parsedData = JSON.parse(data);
					// console.log(parsedData, total_entries);

					setData(parsedData);
					setPagination((prev) => ({
						...prev,
						total_entries: total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);
			toast.dismiss();
		};

		fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [
		user,
		query,
		axios,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return (
		<Container>
			<ContentWrapper
				heading="All Specimens"
				Icon="FingerPrintIcon"
				description="Specimens that have been accessioned by our facility."
			>
				<TableSearchFiltersForm
					disabled={disabled}
					setQuery={setQuery}
					tableType="all-samples"
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="All specimens">
					<ViewSamplesTable
						data={data}
						tableType="all-samples"
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
