import { Method } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import * as Heroicons from '@heroicons/react/24/outline';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Disclosure, Dialog, Transition } from '@headlessui/react';
import { useId, Fragment, ReactNode, useState, useEffect } from 'react';
import {
	Bars3Icon,
	XMarkIcon,
	ChevronRightIcon,
} from '@heroicons/react/24/outline';

import {
	classNames,
	NavigationTab,
	NavigationTabChild,
} from '@pangea-lis-apps/utils';
import { LoadingBox, useAxios, useTabsContext } from '@pangea-lis-apps/ui';

const isTestSite = process.env['NX_ENV'] === 'TESTING';

export interface SidebarNavProps {
	children: ReactNode;
	profileHref: string;
}

export function SidebarNav(props: SidebarNavProps) {
	const { user } = useAuth0();
	const { allTabs } = useTabsContext();

	const [sidebarOpen, setSidebarOpen] = useState(false);

	return (
		<div className="flex max-h-screen">
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog
					as="div"
					onClose={setSidebarOpen}
					className="relative z-40 md:hidden"
				>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
					</Transition.Child>

					<div className="fixed inset-0 flex z-40">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 right-0 -mr-12 pt-2">
										<button
											type="button"
											className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() =>
												setSidebarOpen(false)
											}
										>
											<span className="sr-only">
												Close sidebar
											</span>
											<XMarkIcon
												className="h-6 w-6 text-white"
												aria-hidden="true"
											/>
										</button>
									</div>
								</Transition.Child>
								<div className="flex-1 h-0 overflow-y-auto p-4">
									<div className="flex-shrink-0 flex items-center justify-center px-4 py-6 rounded shadow-sm border border-gray-200 mb-4 select-none">
										<img
											alt="logo"
											className="h-16 w-auto"
											src="https://bentley-internal-test-submission-system-public.s3.amazonaws.com/pangea-lab-logo.png"
										/>
									</div>
									<nav className="mt-4 space-y-2">
										{allTabs ? (
											<NavItems allTabs={allTabs} />
										) : (
											<LoadingBox />
										)}
									</nav>
								</div>
								<div className="flex-shrink-0 flex border-t border-gray-200 p-4">
									<Link
										to={props.profileHref}
										className="flex-shrink-0 group block"
									>
										<div>
											<p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
												{user?.nickname}
											</p>
											<p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
												View Profile
											</p>
										</div>
									</Link>
								</div>
							</Dialog.Panel>
						</Transition.Child>
						<div className="flex-shrink-0 w-14">
							{/* Force sidebar to shrink to fit close icon */}
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			{/* Static sidebar for desktop */}
			<div className="hidden lg:flex lg:flex-shrink-0 lg:overflow-x-hidden">
				<div className="flex w-68 flex-col">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex-1 flex flex-col min-h-screen bg-white border-r border-gray-200">
						{/* Test Version Banner */}
						{isTestSite && (
							<div className="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% flex items-center justify-center py-2 px-4">
								<h1 className="uppercase text-white font-semibold leading tracking-wide text-xs">
									Test Version
								</h1>
							</div>
						)}
						<div className="flex-1 flex flex-col overflow-y-auto p-4">
							<div className="flex items-center justify-center flex-shrink-0 px-4 py-6 rounded shadow-sm border border-gray-200 mb-4 select-none">
								<img
									alt="logo"
									className="h-16 w-auto"
									src="https://bentley-internal-test-submission-system-public.s3.amazonaws.com/pangea-lab-logo.png"
								/>
							</div>
							<nav className="flex-1 bg-white space-y-2">
								{allTabs ? (
									<NavItems allTabs={allTabs} />
								) : (
									<LoadingBox />
								)}
							</nav>
						</div>

						{/* Profile Section */}
						{/* <div className="flex-shrink-0 flex bg-white p-4 border-r border-gray-200 overflow-hidden drop-shadow-[0_-14px_20px_rgba(0,0,0,0.1)]"> */}
						<div className="flex-shrink-0 flex bg-white p-4 border-t border-gray-200 overflow-hidden">
							<Link
								to={props.profileHref}
								className="flex-shrink-0 w-full group block"
							>
								<div>
									<p className="text-sm truncate font-medium text-gray-700 group-hover:text-gray-900">
										{user?.nickname}
									</p>
									<p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
										View Profile
									</p>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>

			{/* Hamburger Menu for Small Screens */}
			<div className="md:pl-0 flex min-w-0 flex-1 flex-col overflow-hidden">
				<div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
					<button
						type="button"
						className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
						onClick={() => setSidebarOpen(true)}
					>
						<span className="sr-only">Open sidebar</span>
						<Bars3Icon className="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<div className="relative z-0 flex flex-1 overflow-hidden">
					{props.children}
				</div>
			</div>
		</div>
	);
}

interface NavItems {
	allTabs: any;
}

const NavItems = ({ allTabs }: NavItems) => {
	const { user } = useAuth0();

	if (!user) return;

	const userIsStaffMember = user['custom_claims/roles'].includes(
		'BITSS_organization-staff'
	);

	if (userIsStaffMember)
		return allTabs.map((item: any, index: number) => {
			return <NavTab key={index} tab={item} />;
		});

	return allTabs.map((item: any) => {
		// For internal employees
		if (item.label)
			return (
				<Disclosure
					as="div"
					key={item.label}
					defaultOpen={true}
					className="space-y-2"
				>
					{({ open }) => (
						<Fragment>
							<Disclosure.Button
								className={classNames(
									open
										? 'bg-slate-200 text-gray-900'
										: 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
									'group w-full flex items-center pl-2 pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
								)}
							>
								<span className="flex-1">{item.label}</span>
								<ChevronRightIcon
									aria-hidden="true"
									className={classNames(
										open
											? 'text-gray-400 rotate-90'
											: 'text-gray-300',
										'ml-3 h-4 w-4 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
									)}
								/>
							</Disclosure.Button>
							<Disclosure.Panel className="space-y-1">
								{item.tabs.map((tab: NavigationTab) => (
									<NavTab key={tab.id} tab={tab} />
								))}
							</Disclosure.Panel>
						</Fragment>
					)}
				</Disclosure>
			);
		// For organization associates (both clinic and nonclinic)
		else
			return item.tabs.map((tab: NavigationTab) => (
				<NavTab key={tab.id} tab={tab} />
			));
	});
};

interface NavTabProps {
	tab: NavigationTab;
}

const NavTab = ({ tab }: NavTabProps) => {
	const { pathname } = useLocation();

	const Icon = tab.icon && Heroicons[tab.icon || 'ExclamationCircleIcon'];

	if (tab.hide) return null;
	else if (tab.children) {
		return (
			<Disclosure
				as="div"
				key={tab.label}
				defaultOpen={true}
				className="space-y-1"
			>
				{({ open }) => (
					<Fragment>
						<Disclosure.Button
							className={classNames(
								open
									? 'bg-gray-100 text-gray-900'
									: 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
								'group w-full flex items-center pl-2 pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 '
							)}
						>
							{Icon && (
								<Icon
									aria-hidden="true"
									className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								/>
							)}
							<span className="flex-1">{tab.label}</span>
							<ChevronRightIcon
								aria-hidden="true"
								className={classNames(
									open
										? 'text-gray-400 rotate-90'
										: 'text-gray-300',
									'ml-3 h-4 w-4 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
								)}
							/>
						</Disclosure.Button>
						<Disclosure.Panel className="space-y-1">
							{tab.children!.map((subTab, index) => {
								if (subTab.hide) return;

								return (
									<NavTabButton
										key={subTab.id}
										subTab={subTab}
									/>
								);
							})}
						</Disclosure.Panel>
					</Fragment>
				)}
			</Disclosure>
		);
	}

	// No children
	return (
		<Link
			to={tab.href}
			key={tab.label}
			className={classNames(
				tab.href === pathname
					? 'bg-gray-100 text-gray-900'
					: 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
				tab.request ? 'flex items-center justify-between' : '',
				'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
			)}
		>
			{Icon && (
				<Icon
					className={classNames(
						tab.href === pathname
							? 'text-gray-500'
							: 'text-gray-400 group-hover:text-gray-500',
						'mr-3 flex-shrink-0 h-6 w-6'
					)}
					aria-hidden="true"
				/>
			)}
			{tab.label}
			{tab.request && <NotificationNumber request={tab.request} />}
		</Link>
	);
};

interface NavTabButtonProps {
	subTab: NavigationTabChild;
}

const NavTabButton = (props: NavTabButtonProps) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<button
			key={props.subTab.label}
			onClick={() => navigate(props.subTab.href)}
			className={classNames(
				props.subTab.href === pathname
					? 'bg-blue-100'
					: 'hover:bg-gray-50',
				props.subTab.request ? 'flex items-center justify-between' : '',
				'text-left w-full rounded-md py-2 pl-11 pr-2 disabled:cursor-not-allowed'
			)}
		>
			<p
				className={classNames(
					props.subTab.href === pathname
						? 'text-blue-900'
						: 'text-gray-600 hover:text-gray-900',
					'text-sm font-medium'
				)}
			>
				{props.subTab.label}
			</p>
			{props.subTab.request && (
				<NotificationNumber request={props.subTab.request} />
			)}
		</button>
	);
};

interface NotificationNumberProps {
	request: {
		url: string;
		method: Method;
		payload: { [key: string]: string | boolean };
	};
}

const NotificationNumber = (props: NotificationNumberProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();

	const [notificationNumber, setNotificationNumber] = useState<
		number | undefined
	>(undefined);

	// useEffect(() => {
	// 	let unmounted = false;

	// 	const fetchData = async () => {
	// 		if (!axios) return;

	// 		try {
	// 			const { url, method, payload } = props.request;

	// 			const {
	// 				data: { data },
	// 			} = await (
	// 				await axios
	// 			)({
	// 				url,
	// 				method,
	// 				data: {
	// 					user,
	// 					query: payload,
	// 				},
	// 			});

	// 			if (!unmounted) {
	// 				const notificationNumber = JSON.parse(data);
	// 				setNotificationNumber(notificationNumber);
	// 			}
	// 		} catch (error) {
	// 			console.log(error);
	// 		}
	// 	};

	// 	fetchData();
	// 	const intervalID = setInterval(fetchData, 30000);

	// 	return () => {
	// 		unmounted = true;
	// 		clearTimeout(intervalID);
	// 	};
	// }, [axios, props.request]);

	if (!notificationNumber) return null;

	return (
		<div className="bg-blue-500 shadow rounded-lg px-3 py-1">
			<p className="text-xs text-white font-semibold">
				{notificationNumber}
			</p>
		</div>
	);
};
