import toast from 'react-hot-toast';
import {
	Fragment,
	useState,
	Dispatch,
	ChangeEvent,
	SetStateAction,
	MouseEventHandler,
} from 'react';

import {
	DateField,
	FormGroup,
	SelectField,
	GenericField,
	SearchFiltersForm,
	AssaySpecificFilters,
} from '@pangea-lis-apps/ui';
import {
	ASSAYS,
	DateRange,
	Pagination,
	getSpecimenTypes,
	getSampleStatuses,
	TableSearchFunctions,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';

// import SelectAssociate from '../../shared/components/select-associate';

interface FormValues {
	'customer._id'?: string;
	'sample.status'?: string;
	'organization._id'?: string;
	'sample.priority'?: string;
	'sample.pangea_id'?: string;
	'sample.specimen_id'?: string;
	'sample.collection_kit_id'?: string;
	'sample.sample_received_date'?: string | DateRange;
	[key: string]: string | string[] | undefined | DateRange;
	'sample.sample_collection_date'?: string | DateRange;
	'sample.assay'?: string;
	'sample.sample_specimen_type'?: string;
}

const initialFormValues = {
	'sample.status': '',
	'customer._id': '',
	'organization._id': '',
	'sample.priority': '',
	'sample.pangea_id': '',
	'sample.specimen_id': '',
	'sample.collection_kit_id': '',
	'sample.sample_received_date': '',
	'sample.sample_collection_date': '',
	'sample.assay': '',
	'sample.sample_specimen_type': '',
};

const initialDateRangesDisplayValues = {
	'sample.sample_received_date': false,
	'sample.sample_collection_date': false,
};

const dateRangeProperties = [
	'sample.sample_received_date',
	'sample.sample_collection_date',
];

interface TableSearchFiltersFormProps {
	disabled: boolean;
	tableType:
		| 'report'
		| 'all-samples'
		| 'flagged-samples'
		| 'rejected-samples'
		| 'patients';
	setQuery: Dispatch<SetStateAction<FormValues>>;
	setPagination: Dispatch<SetStateAction<Pagination>>;
}

export default function TableSearchFiltersForm({
	setQuery,
	disabled,
	tableType,
	setPagination,
}: TableSearchFiltersFormProps) {
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

	// For search filters
	const [dateRangesDisplay, setDateRangesDisplay] = useState<{
		[key: string]: boolean;
	}>(initialDateRangesDisplayValues);

	const handleChange = (event: ChangeEvent) =>
		TableSearchFunctions.handleChange(
			event,
			setFormValues,
			dateRangeProperties,
			dateRangesDisplay
		);

	const handleChangeDateDisplay = (propName: string) =>
		TableSearchFunctions.handleChangeDateDisplay(
			propName,
			setDateRangesDisplay,
			setFormValues
		);

	const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (
		event
	) => {
		event.preventDefault();

		if (!TableSearchFunctions.hasAtLeastOneNonNullValue(formValues)) {
			toast.error('Provide a value for at least one filter criterion.');
			return;
		}

		const normalizedFormValues =
			TableSearchFunctions.normalizeFormValues(formValues);

		setQuery(normalizedFormValues);
		setPagination((prevValue) => ({
			...prevValue,
			page_number: 1,
		}));
	};

	return (
		<SearchFiltersForm
			disabled={disabled}
			handleSubmit={handleSubmit}
			clearFilters={() => {
				setQuery({});
				setFormValues(initialFormValues);
				setPagination(initialPaginationValues);
				setDateRangesDisplay(initialDateRangesDisplayValues);
			}}
		>
			{/* <SelectAssociate
				value={formValues['customer._id'] || ''}
				setQuery={setQuery}
				requiredPermission={(() => {
					if (tableType === 'report') return 'view_reports';
					else if (
						tableType === 'all-samples' ||
						tableType === 'flagged-samples' ||
						tableType === 'rejected-samples'
					)
						return 'manage_samples';

					return null;
				})()}
			/> */}

			<FormGroup heading="Specimen filters">
				{(tableType === 'all-samples' || tableType === 'report') && (
					<Fragment>
						<div className="sm:col-span-2">
							<SelectField
								name="sample.status"
								label="Specimen Status"
								handleSelect={handleChange}
								value={formValues['sample.status'] || ''}
								options={[
									{
										label: 'Select an Option',
										value: '',
									},
									...getSampleStatuses(true, [
										'received',
										'flag',
										'hold',
										'needs_review',
										'reviewed',
										'pending',
										'pending_verification',
									]),
								]}
							/>
						</div>
						<div className="sm:col-span-4"></div>
					</Fragment>
				)}
				<div className="sm:col-span-2">
					<GenericField
						type="text"
						label="Specimen ID"
						name="sample.specimen_id"
						placeholder="e.g., XZ12345678"
						handleInputChange={handleChange}
						value={formValues['sample.specimen_id'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<GenericField
						type="text"
						label="Collection Kit ID"
						name="sample.collection_kit_id"
						placeholder="e.g., XZ12345678"
						handleInputChange={handleChange}
						value={formValues['sample.collection_kit_id'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<GenericField
						type="text"
						label="Pangea ID"
						name="sample.pangea_id"
						placeholder="e.g., XZ12345678"
						handleInputChange={handleChange}
						value={formValues['sample.pangea_id'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<SelectField
						label="Test"
						name="sample.assay"
						options={[
							{
								value: '',
								label: 'Select an Option',
							},
							...ASSAYS['assays'],
						]}
						handleSelect={handleChange}
						value={formValues['sample.assay'] || ''}
					/>
				</div>
				<div className="sm:col-span-2">
					<SelectField
						label="Specimen Type"
						options={getSpecimenTypes()}
						name="sample.sample_specimen_type"
						handleSelect={handleChange}
						value={formValues['sample.sample_specimen_type'] || ''}
					/>
				</div>
				<div className="sm:col-span-2"></div>
				<div className="sm:col-span-2">
					<DateField
						label="Collection Date"
						range={
							dateRangesDisplay['sample.sample_collection_date']
						}
						handleChangeDateDisplay={handleChangeDateDisplay}
						name="sample.sample_collection_date"
						handleInputChange={handleChange}
						value={
							formValues['sample.sample_collection_date'] || ''
						}
					/>
				</div>
				<div className="sm:col-span-2">
					<DateField
						label="Received Date"
						range={dateRangesDisplay['sample.sample_received_date']}
						handleChangeDateDisplay={handleChangeDateDisplay}
						name="sample.sample_received_date"
						handleInputChange={handleChange}
						value={formValues['sample.sample_received_date'] || ''}
					/>
				</div>
			</FormGroup>

			<FormGroup heading="Test-specific filters">
				<div className="sm:col-span-2">
					<SelectField
						label="Test"
						name="sample.assay"
						handleSelect={handleChange}
						value={formValues['sample.assay'] || ''}
						options={[
							{
								value: '',
								label: 'Select an Option',
							},
							...ASSAYS['assays'],
						]}
					/>
				</div>
				<AssaySpecificFilters
					formValues={formValues}
					handleChange={handleChange}
					assay={formValues['sample.assay'] || ''}
				/>
			</FormGroup>
		</SearchFiltersForm>
	);
}
