import { Ref, ReactNode, ChangeEventHandler } from 'react';

import { Label } from '../label/label';

import { TextButton } from '@pangea-lis-apps/ui';
import { DateRange, classNames } from '@pangea-lis-apps/utils';

export interface DateFieldProps {
	min?: string;
	max?: string;
	name: string;
	label?: string;
	noMax?: boolean;
	range?: boolean;
	required?: boolean;
	disabled?: boolean;
	fieldAction?: ReactNode;
	showRequiredAsterisk?: boolean;
	value: string | number | DateRange;
	inputFieldRef?: Ref<HTMLInputElement> | undefined;
	handleChangeDateDisplay?: (propName: string) => void;
	handleInputChange: ChangeEventHandler<HTMLInputElement> | undefined;
}

export function DateField(props: DateFieldProps) {
	return (
		<div>
			{props.label && (
				// Show the disabled UI state for these components. Want to show disabled state for date field when the sample is not provided.
				<div
					className={classNames('flex items-center justify-between')}
				>
					<Label
						name={props.name}
						label={props.label}
						required={props.required || props.showRequiredAsterisk}
					/>
					{props.handleChangeDateDisplay && (
						<TextButton
							color="gray"
							type="button"
							className="!text-xs"
							text={props.range ? 'Select date' : 'Select Range'}
							onClick={() => {
								props.handleChangeDateDisplay &&
									props.handleChangeDateDisplay(props.name);
							}}
						/>
					)}
					{props.fieldAction && props.fieldAction}
				</div>
			)}
			{props.range ? (
				<div className="flex items-center space-x-2">
					<input
						type="date"
						id={props.name}
						min="1900-01-01"
						name={props.name}
						disabled={props.disabled}
						required={props.required}
						data-datetype="date_start"
						value={(props.value as DateRange).date_start}
						onChange={props.handleInputChange}
						max={new Date().toISOString().split('T')[0]}
						className="mt-1 block w-full shadow-sm sm:text-sm rounded-md focus:ring-2 focus:ring-blue-200 focus:border-blue-500 border-gray-300 disabled:bg-gray-100 disabled:text-gray-400 disabled:font-medium disabled:hover:cursor-not-allowed"
					/>
					<input
						type="date"
						id={props.name}
						name={props.name}
						data-datetype="date_end"
						required={props.required}
						disabled={props.disabled}
						value={(props.value as DateRange).date_end}
						onChange={props.handleInputChange}
						max={new Date().toISOString().split('T')[0]}
						min={(() => {
							if ((props.value as DateRange).date_start) {
								const newMinDate = new Date(
									(props.value as DateRange).date_start
								);

								newMinDate.setDate(newMinDate.getDate() + 1);

								return newMinDate.toISOString().split('T')[0];
							}

							return (props.value as DateRange).date_start;
						})()}
						className="mt-1 block w-full shadow-sm sm:text-sm rounded-md focus:ring-2 focus:ring-blue-200 focus:border-blue-500 border-gray-300 disabled:bg-gray-100 disabled:text-gray-400 disabled:font-medium disabled:hover:cursor-not-allowed"
					/>
				</div>
			) : (
				<input
					type="date"
					id={props.name}
					name={props.name}
					disabled={props.disabled}
					ref={props.inputFieldRef}
					required={props.required}
					value={props.value as string}
					onChange={props.handleInputChange}
					min={props.min ? props.min : '1900-01-01'}
					max={
						props.noMax
							? ''
							: props.max
							? props.max
							: new Date().toISOString().split('T')[0]
					}
					className="mt-1 block w-full shadow-sm sm:text-sm rounded-md focus:ring-2 focus:ring-blue-200 focus:border-blue-500 border-gray-300 disabled:bg-gray-100 disabled:text-gray-400 disabled:font-medium disabled:hover:cursor-not-allowed"
				/>
			)}
		</div>
	);
}

export default DateField;
