import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';

/* eslint-disable-next-line */
export interface MaintenancePageProps {}

export function MaintenancePage(props: MaintenancePageProps) {
	return (
		<div className="bg-gray-100 min-h-screen flex items-center justify-center px-4">
			<div className="max-w-sm border border-100 bg-white p-6 rounded-lg shadow">
				<div className="bg-gray-100 p-2 border border-gray-300 rounded-md inline-block shadow-sm">
					<WrenchScrewdriverIcon className="w-8 h-8 text-gray-500" />
				</div>
				<h1 className="text-xl font-semibold tracking-tight mt-3">
					Site under maintenance
				</h1>
				<p className="text-base text-gray-600 mt-2">
					We will back shortly. We apologize for any inconvenience. If
					you need to reach out to us for any reason, send us a{' '}
					<a
						href="mailto:info@bitss.pangea-bentley.com"
						className="text-blue-500 hover:text-blue-700 font-medium"
					>
						message
					</a>
					.
				</p>
			</div>
		</div>
	);
}

export default MaintenancePage;
