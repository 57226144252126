import { ReactNode } from 'react';

export interface SectionDescriptionProps {
	styles?: string;
	content: ReactNode;
}

export function SectionDescription(props: SectionDescriptionProps) {
	return (
		<p className={`text-gray-500 text-xs ${props.styles}`}>
			{props.content}
		</p>
	);
}

export default SectionDescription;
