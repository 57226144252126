import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface PublicPageWrapperProps {
	heading: string;
	subheading?: string;
	children: ReactNode;
	description: string;
}

export function PublicPageWrapper(props: PublicPageWrapperProps) {
	return (
		<div className="bg-white h-screen flex flex-col lg:relative">
			<div className="flex-grow flex flex-col">
				<main className="flex-grow flex flex-col bg-white">
					<div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
						<div className="flex-shrink-0 pt-10 sm:pt-16">
							<Link to="/" className="inline-flex">
								<span className="sr-only">Logo</span>
								<img
									alt="Logo"
									className="h-16 w-auto"
									src="https://bentley-internal-test-submission-system-public.s3.amazonaws.com/pangea-lab-logo.png"
								/>
							</Link>
						</div>
						<div className="flex-shrink-0 my-auto py-16 sm:py-32">
							{props.subheading && (
								<p className="text-sm font-semibold text-blue-600 uppercase tracking-wide">
									{props.subheading}
								</p>
							)}
							<h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
								{props.heading}
							</h1>
							<p className="mt-4 text-base text-gray-500 max-w-md">
								{props.description}
							</p>
							<div className="mt-6">{props.children}</div>
						</div>
					</div>
				</main>
				<footer className="flex-shrink-0 bg-gray-50">
					<div className="mx-auto max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
						<nav className="flex space-x-4">
							<a
								href="mailto:clientservices@pangealab.com"
								className="text-sm font-medium text-gray-500 hover:text-gray-600"
							>
								Contact Support
							</a>
						</nav>
					</div>
				</footer>
			</div>
			<div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
				<img
					alt="Background"
					src="/assets/auth-bg.jpg"
					className="absolute inset-0 h-full w-full object-cover"
				/>
			</div>
		</div>
	);
}

export default PublicPageWrapper;
