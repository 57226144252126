import { ChangeEvent } from 'react';

import { SelectField } from '@pangea-lis-apps/ui';
import {
	FORMS,
	Option,
	DateRange,
	getAllRequisitionFormOptions,
} from '@pangea-lis-apps/utils';

interface AssaySpecificFiltersProps {
	assay: string;
	formValues: Record<string, string | string[] | undefined | DateRange>;
	handleChange: (event: ChangeEvent) => void;
}

export function AssaySpecificFilters(props: AssaySpecificFiltersProps) {
	if (props.assay === 'sti' || props.assay === 'resp4plex')
		return (
			<AlinityMSearchFilters
				assay={props.assay}
				formValues={props.formValues}
				handleChange={props.handleChange}
			/>
		);
	else if (props.assay === 'bladdercare')
		return (
			<BladderCARESearchFilters
				assay={props.assay}
				formValues={props.formValues}
				handleChange={props.handleChange}
			/>
		);

	return null;
}

function AlinityMSearchFilters(props: AssaySpecificFiltersProps) {
	return (
		<div className="sm:col-span-2">
			<SelectField
				label="Positive(s) exist?"
				options={FORMS.yes_no_options}
				handleSelect={props.handleChange}
				name="sample.results.from_all_positive_exists"
				value={
					(props.formValues[
						'sample.results.from_all_positive_exists'
					] as string) || ''
				}
			/>
		</div>
	);
}

function BladderCARESearchFilters(props: AssaySpecificFiltersProps) {
	const results = Object.values(
		getAllRequisitionFormOptions('result')
	) as Option[];

	if (!results) return null;

	return (
		<div className="sm:col-span-2">
			<SelectField
				label="Results"
				options={[
					{
						value: '',
						disabled: true,
						label: 'Select an Option',
					},
					...results,
				]}
				handleSelect={props.handleChange}
				name="sample.results.result"
				value={
					(props.formValues['sample.results.result'] as string) || ''
				}
			/>
		</div>
	);
}
