import { ReactNode, useState, useEffect } from 'react';

import { Button, SectionHeading } from '@pangea-lis-apps/ui';
import {
	Pagination,
	classNames,
	PaginationState,
} from '@pangea-lis-apps/utils';

export interface TableProps {
	heading?: string;
	className?: string;
	children: ReactNode;
	pagination?: PaginationState;
}

export interface PaginationDetails {
	pageNumber: number;
	totalPages: number;
	endingNumber: number;
	totalEntries: number;
	startingNumber: number;
}

export function Table(props: TableProps) {
	const [entryInformation, setEntryInformation] = useState<PaginationDetails>(
		{
			pageNumber: 0,
			totalPages: 0,
			endingNumber: 0,
			totalEntries: 0,
			startingNumber: 0,
		}
	);

	useEffect(() => {
		if (props.pagination) {
			const {
				pagination: { entries_per_page, total_entries, page_number },
			} = props.pagination;

			let startingNumber = (page_number - 1) * entries_per_page + 1;
			let endingNumber = page_number * entries_per_page;

			if (!total_entries) startingNumber = 0;
			if (endingNumber > total_entries) endingNumber = total_entries;

			setEntryInformation({
				startingNumber,
				endingNumber,
				pageNumber: page_number,
				totalEntries: total_entries,
				totalPages: Math.ceil(total_entries / entries_per_page),
			});
		}
	}, [props.pagination]);

	const handlePrevious = () => {
		if (props.pagination) {
			const { pagination, setPagination } = props.pagination;

			const new_page_number = pagination.page_number - 1;

			if (new_page_number)
				setPagination((prevState: Pagination) => ({
					...prevState,
					page_number: new_page_number,
				}));
		}
	};

	const handleNext = () => {
		if (props.pagination) {
			const { pagination, setPagination } = props.pagination;

			const total_pages = Math.ceil(
				pagination.total_entries / pagination.entries_per_page
			);
			const new_page_number = pagination.page_number + 1;

			if (new_page_number <= total_pages)
				setPagination((prevState: Pagination) => ({
					...prevState,
					page_number: new_page_number,
				}));
		}
	};

	return (
		<div className={props.className && props.className}>
			{props.heading && (
				<SectionHeading text={props.heading} className="mb-4" />
			)}{' '}
			<div className="border flex flex-col rounded-lg">
				<div className="align-middle w-full overflow-x-auto shadow sm:rounded-t-lg">
					<table
						className={classNames(
							'w-full divide-y divide-gray-200 table-fixed',
							props.pagination ? 'border-b border-gray-200' : ''
						)}
					>
						{props.children}
					</table>
				</div>
				{props.pagination ? (
					<nav
						aria-label="pagination"
						className="shadow sm:rounded-b-lg bg-white px-4 py-3 flex items-center justify-between"
					>
						<div className="hidden sm:block">
							<p className="text-sm text-gray-700">
								Showing{' '}
								<span className="font-medium">
									{entryInformation.startingNumber}
								</span>{' '}
								to{' '}
								<span className="font-medium">
									{entryInformation.endingNumber}
								</span>{' '}
								of{' '}
								<span className="font-medium">
									{entryInformation.totalEntries}
								</span>{' '}
								Results
							</p>
						</div>
						<div className="flex-1 flex justify-between sm:justify-end space-x-2">
							{entryInformation.pageNumber !== 1 && (
								<Button
									type="button"
									tier="tertiary"
									text="Previous"
									onClick={handlePrevious}
								/>
							)}
							{entryInformation.pageNumber !==
								entryInformation.totalPages &&
								entryInformation.totalPages !== 0 && (
									<Button
										text="Next"
										type="button"
										tier="tertiary"
										onClick={handleNext}
									/>
								)}
						</div>
					</nav>
				) : (
					<div className="border-t shadow sm:rounded-b-lg bg-white px-4 py-3"></div>
				)}
			</div>
		</div>
	);
}

export default Table;
