import { Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, Outlet, Navigate } from 'react-router-dom';

import { LoadingPage } from '@pangea-lis-apps/ui';
import { useTabsContext } from '../../context/tabs/tabs';

interface PublicRouteProps {}

export const PublicRoute = (props: PublicRouteProps) => {
	const { pathname } = useLocation();
	const { user, logout, isLoading, isAuthenticated, error } = useAuth0();

	const { allTabs } = useTabsContext();

	if (pathname === '/blocked') return <OutletComponent />;

	if (error) {
		if (error.message === 'user is blocked') {
			if (pathname !== '/blocked') {
				return <Navigate to="/blocked" replace />;
			} else {
				return <OutletComponent />;
			}
		} else {
			logout({
				returnTo: window.location.origin,
			});

			return null;
		}
	}

	if (isLoading) {
		return <LoadingPage />;
	} else {
		if (isAuthenticated) {
			if (user && allTabs) {
				if (!user.email_verified) {
					if (pathname !== '/verify-email') {
						return <Navigate to="/verify-email" replace />;
					}

					return <OutletComponent />;
				}

				if (allTabs.length === 0) {
					if (pathname !== '/access-pending') {
						return <Navigate to="/access-pending" replace />;
					}

					return <OutletComponent />;
				} else {
					// Direct to first tab
					const userIsStaffMember = user[
						'custom_claims/roles'
					].includes('BITSS_organization-staff');

					if (userIsStaffMember) {
						sessionStorage.setItem(
							'currentTab',
							allTabs[0].children[0].id
						);

						return (
							<Navigate
								to={allTabs[0].children[0].href}
								replace
							/>
						);
					} else {
						sessionStorage.setItem(
							'currentTab',
							allTabs[0].tabs[0].children
								? allTabs[0].tabs[0].children[0].id
								: allTabs[0].tabs[0].id
						);

						return (
							<Navigate
								to={
									allTabs[0].tabs[0].children
										? allTabs[0].tabs[0].children[0].href
										: allTabs[0].tabs[0].href
								}
								replace
							/>
						);
					}
				}
			}

			return <LoadingPage />;
		} else {
			return <OutletComponent />;
		}
	}
};

function OutletComponent() {
	const isTestSite = process.env['NX_ENV'] === 'TESTING';

	return (
		<Fragment>
			{isTestSite && (
				<div className="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% flex items-center justify-center py-2 px-8">
					<h1 className="uppercase text-white font-semibold leading tracking-wide text-xs">
						Test Version
					</h1>
				</div>
			)}

			<Outlet />
		</Fragment>
	);
}

export default PublicRoute;
