import { ContentSection } from '@pangea-lis-apps/ui';

/* eslint-disable-next-line */
export interface LoadingBoxProps {}

export function LoadingBox(props: LoadingBoxProps) {
	return (
		<ContentSection>
			<div className="flex items-center justify-center h-48">
				<svg
					width="60"
					height="60"
					fill="none"
					viewBox="0 0 60 60"
					className="w-8 h-8 animate-spin"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M31.6094 56.952C24.4613 57.3788 17.4363 54.9486 12.0801 50.1959C6.72376 45.4433 3.47484 38.7575 3.04801 31.6094C2.62118 24.4613 5.05141 17.4363 9.80407 12.0801C14.5567 6.72376 21.2425 3.47484 28.3906 3.04801C35.5387 2.62118 42.5637 5.0514 47.9199 9.80407C53.2762 14.5567 56.5252 21.2425 56.952 28.3906C57.3788 35.5387 54.9486 42.5636 50.1959 47.9199C45.4433 53.2762 38.7575 56.5252 31.6094 56.952L31.6094 56.952Z"
						stroke="#EDEDED"
						strokeWidth="6"
					/>
					<path
						d="M9.9041 48.0321C7.21415 45.0342 5.22898 41.4725 4.09405 37.608C2.95911 33.7434 2.70318 29.6739 3.34499 25.6976C3.9868 21.7213 5.5101 17.939 7.80326 14.6278C10.0964 11.3166 13.1014 8.56034 16.5979 6.56106"
						stroke="#2563EB"
						strokeWidth="6"
					/>
				</svg>
			</div>
		</ContentSection>
	);
}

export default LoadingBox;
