export interface FormErrorsProps {
	data: string[];
	errorLabels: { [key: string]: string };
}

export function FormErrors(props: FormErrorsProps) {
	return (
		<ul className="list-inside list-disc">
			{props.data.map((key) => (
				<li key={key}>
					{props.errorLabels[key] || key.replace(/_/g, ' ')}
				</li>
			))}
		</ul>
	);
}

export default FormErrors;
