import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useId, useState, useEffect } from 'react';

import {
	ClinicData,
	Pagination,
	initialPaginationValues,
} from '@pangea-lis-apps/utils';
import {
	useAxios,
	Container,
	ContentWrapper,
	ContentSection,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';

import ViewSamplesTable from '../../../components/view-samples-table';
import TableSearchFiltersForm from '../../../components/table-search-filters-form';

/* eslint-disable-next-line */
export interface ViewFlaggedSamplesProps {}

export default function ViewFlaggedSamples(props: ViewFlaggedSamplesProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user } = useAuth0();

	const [query, setQuery] = useState({});
	const [disabled, setDisabled] = useState(false);
	const [data, setData] = useState<ClinicData[] | undefined>(undefined);
	const [pagination, setPagination] = useState<Pagination>(
		initialPaginationValues
	);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios) return;

			try {
				if (!unmounted) {
					setData(undefined);
					setDisabled(false);
				}

				toast.loading('Loading...');

				const {
					data: {
						data: { data, total_entries },
					},
				} = await (
					await axios
				).post(
					`/api/organization-shared/search/data?page_number=${pagination.page_number}&entries_per_page=${pagination.entries_per_page}`,
					{
						query: {
							...query,
							'sample.status': 'flag',
						},
					}
				);

				if (!unmounted) {
					// console.log(JSON.parse(data), total_entries);

					setData(JSON.parse(data));
					setPagination((prev) => ({
						...prev,
						total_entries,
					}));
				}
			} catch (error) {
				console.log(error);
			}

			if (!unmounted) setDisabled(false);
			toast.dismiss();
		};

		fetchData();

		return () => {
			unmounted = true;
			toast.dismiss();
		};
	}, [
		user,
		query,
		axios,
		pagination.page_number,
		pagination.entries_per_page,
	]);

	return (
		<Container>
			<ContentWrapper
				Icon="FlagIcon"
				heading="Specimens On-Hold"
				description="Specimens on-hold for illegible, discrepant and/or missing information."
			>
				<TableSearchFiltersForm
					tableType="flagged-samples"
					disabled={disabled}
					setQuery={setQuery}
					setPagination={setPagination}
				/>

				<ContentSectionDivider />

				<ContentSection heading="All specimens on-hold">
					<ViewSamplesTable
						data={data}
						tableType="flagged-samples"
						query={{ query, setQuery }}
						pagination={{ pagination, setPagination }}
					/>
				</ContentSection>
			</ContentWrapper>
		</Container>
	);
}
