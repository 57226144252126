import { ReactNode } from 'react';
import * as Heroicons from '@heroicons/react/24/solid';

import { Date, IconName } from '@pangea-lis-apps/utils';
import { DescriptionTerm, DescriptionDetails } from '@pangea-lis-apps/ui';

export interface DescriptionItemProps {
	term: string;
	Icon?: IconName;
	children?: ReactNode;
	withFunction?: boolean;
	customColSpan?: string;
	handleClick?: () => void;
	termStyles?: string | null;
	details?: string | number | Date | boolean | undefined | string[] | null;
}

export function DescriptionItem(props: DescriptionItemProps) {
	const Icon = Heroicons[props.Icon || 'ExclamationCircleIcon'];

	return (
		<div
			className={
				props.customColSpan ? props.customColSpan : 'sm:col-span-1'
			}
		>
			<div className="flex items-center">
				<DescriptionTerm className={props.termStyles}>
					{props.term}
				</DescriptionTerm>
				{props.withFunction && (
					<Icon
						aria-hidden="true"
						onClick={props.handleClick}
						className="ml-1 h-3 w-3 text-gray-400 hover:text-gray-500 cursor-pointer"
					/>
				)}
			</div>
			<DescriptionDetails>
				{(() => {
					if (props.children) return props.children;

					if (typeof props.details === 'boolean') {
						if (props.details) return 'Yes';

						return 'No';
					} else if (
						!props.details ||
						props.details === undefined ||
						props.details === null
					) {
						return <span className="text-gray-500">-</span>;
					} else if (
						typeof props.details === 'object' &&
						'$date' in props.details
					) {
						return props.details.$date;
					}

					return props.details;
				})()}
			</DescriptionDetails>
		</div>
	);
}

export default DescriptionItem;
