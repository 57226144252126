import './styles.css';
import { Fragment } from 'react';
import * as Sentry from '@sentry/react';
import { Toaster } from 'react-hot-toast';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
	Index,
	Button,
	Blocked,
	Results,
	Callback,
	VerifyEmail,
	PublicRoute,
	ScrollToTop,
	PageNotFound,
	LoadingModal,
	AccessPending,
	ProtectedRoute,
} from '@pangea-lis-apps/ui';

// Clinic
import ViewPatients from './app/clinic/views/shared/patients/view-patients';
import SearchReports from './app/clinic/views/shared/reports/search-reports';
import ViewNewReports from './app/clinic/views/shared/reports/view-new-reports';
import ViewPatient from './app/clinic/views/shared/patients/view-patient/index';
import ViewAllSamples from './app/clinic/views/shared/samples/view-all-samples';
import ViewFlaggedSamples from './app/clinic/views/shared/samples/view-flagged-samples';
import ViewRejectedSamples from './app/clinic/views/shared/samples/view-rejected-samples';

// Non-clinic
import NonClinicAllSamples from './app/non-clinic/views/samples/view-all-samples';
import NonClinicViewNewReports from './app/non-clinic/views/reports/view-new-reports';
import NonClinicSearchReports from './app/non-clinic/views/reports/search-reports';
import NonClinicFlaggedSamples from './app/non-clinic/views/samples/view-flagged-samples';
import NonClinicRejectedSamples from './app/non-clinic/views/samples/view-rejected-samples';

// Shared and Utils
import Profile from './app/shared/views/profile';
import ViewSample from './app/shared/views/view-sample';

import ContextProviders from './utils/context/providers';
import HelpButton from './app/shared/components/help-button';
import {
	ClinicProtectedRoute,
	NonClinicAssociateProtectedRoute,
} from './utils/routing';
import toasterOptions from '../../../libs/utils/src/lib/toaster-options.json';

Sentry.init({
	dsn: 'https://e63ce35041d8bc5049ac2be78805cc25@o4507897152339968.ingest.us.sentry.io/4507901351886848',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const handleTestSentryIntegration = () => {
	throw new Error('Test Error');
};

root.render(
	<Fragment>
		<Toaster toastOptions={toasterOptions} />

		{/* {process.env['NX_ENV'] === 'DEVELOPMENT' && (
			<div className="text-right bg-gray-50 border border-gray-200 p-6">
				<Button
					type="button"
					tier="tertiary"
					Icon="CheckIcon"
					text="Test Sentry Integration"
					onClick={handleTestSentryIntegration}
				/>
			</div>
		)} */}

		<BrowserRouter>
			<ScrollToTop />
			<ContextProviders>
				<HelpButton />
				<LoadingModal />
				<Routes>
					<Route element={<PublicRoute />}>
						<Route index element={<Index />} />
						<Route path="/results" element={<Results />} />
						<Route path="/callback/" element={<Callback />} />
						<Route
							path="/access-pending"
							element={<AccessPending />}
						/>
						<Route path="/blocked" element={<Blocked />} />
						<Route path="/verify-email" element={<VerifyEmail />} />
					</Route>
					<Route
						element={
							<ProtectedRoute profileHref="/organization-user/profile" />
						}
					>
						<Route element={<NonClinicAssociateProtectedRoute />}>
							<Route
								path="nco/reports/new"
								element={<NonClinicViewNewReports />}
							/>
							<Route
								path="nco/reports/search"
								element={<NonClinicSearchReports />}
							/>
							<Route
								path="nco/specimens"
								element={<NonClinicAllSamples />}
							/>
							<Route
								path="nco/specimens/rejected"
								element={<NonClinicRejectedSamples />}
							/>
							<Route
								path="nco/specimens/flagged"
								element={<NonClinicFlaggedSamples />}
							/>
							<Route
								path="nco/specimens/:dataId"
								element={<ViewSample />}
							/>
						</Route>
						<Route
							element={
								<ClinicProtectedRoute requiredPermissionForStaff="read:report" />
							}
						>
							<Route
								path="co/reports/search"
								element={<SearchReports />}
							/>
							<Route
								path="co/reports/new"
								element={<ViewNewReports />}
							/>
						</Route>
						<Route
							element={
								<ClinicProtectedRoute requiredPermissionForStaff="read:sample" />
							}
						>
							<Route
								path="co/specimens"
								element={<ViewAllSamples />}
							/>
							<Route
								path="co/specimens/flagged"
								element={<ViewFlaggedSamples />}
							/>
							<Route
								path="co/specimens/rejected"
								element={<ViewRejectedSamples />}
							/>
							<Route
								path="co/specimens/:dataId"
								element={<ViewSample />}
							/>
						</Route>
						<Route
							element={
								<ClinicProtectedRoute requiredPermissionForStaff="read:patient" />
							}
						>
							<Route
								path="co/patients"
								element={<ViewPatients />}
							/>
							<Route
								path="co/patients/:patientId"
								element={<ViewPatient />}
							/>
						</Route>
						<Route
							element={<Profile />}
							path="/organization-user/profile"
						/>
						<Route path="*" element={<PageNotFound />} />
					</Route>
					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</ContextProviders>
		</BrowserRouter>
	</Fragment>
);
