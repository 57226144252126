import { ReactNode, ChangeEventHandler, forwardRef } from 'react';

import { Label } from '@pangea-lis-apps/ui';

export interface FileFieldProps {
	name: string;
	label: string;
	accept?: string;
	multiple?: boolean;
	disabled?: boolean;
	required?: boolean;
	fieldAction?: ReactNode;
	showRequiredAsterisk?: boolean;
	handleInputChange: ChangeEventHandler<HTMLInputElement> | undefined;
}

export const FileField = forwardRef<HTMLInputElement, FileFieldProps>(
	(props, ref) => {
		return (
			<div>
				<div className="flex items-center justify-between">
					<Label
						name={props.name}
						label={props.label}
						required={props.required || props.showRequiredAsterisk}
					/>
					{props.fieldAction && props.fieldAction}
				</div>
				<input
					ref={ref}
					type="file"
					name={props.name}
					multiple={props.multiple}
					disabled={props.disabled}
					required={props.required}
					onChange={props.handleInputChange}
					accept={props.accept ? props.accept : '.pdf'}
					className="mt-2 text-sm text-grey-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-gray-100 file:text-gray-700 hover:file:bg-gray-200 hover:file:text-gray-900 disabled:cursor-not-allowed file:disabled:cursor-not-allowed file:disabled:hover:bg-gray-100 file:disabled:hover:text-gray-700"
				/>
			</div>
		);
	}
);

export default FileField;
