import { ReactNode } from 'react';

import { SectionHeading, SectionDescription } from '@pangea-lis-apps/ui';
export interface ContentSectionProps {
	id?: string;
	heading?: string;
	className?: string;
	children: ReactNode;
	customWidth?: string;
	description?: ReactNode;
	headingAction?: ReactNode;
}

export function ContentSection(props: ContentSectionProps) {
	return (
		<section
			id={props.id}
			className={`px-4 sm:px-6 lg:px-8 ${props.customWidth} ${props.className}`}
		>
			{(props.heading || props.description || props.headingAction) && (
				<div className="flex items-center justify-between mb-4">
					<div>
						{props.heading && (
							<SectionHeading
								text={props.heading}
								className={props.description ? 'mb-2' : ''}
							/>
						)}
						{props.description && (
							<SectionDescription content={props.description} />
						)}
					</div>

					{props.headingAction}
				</div>
			)}

			{props.children}
		</section>
	);
}

export default ContentSection;
