import { ReactNode } from 'react';

export interface TableHeadProps {
	children: ReactNode;
}

export function TableHead(props: TableHeadProps) {
	return (
		<thead>
			<tr className="bg-gray-50 divide-x divide-gray-200">
				{props.children}
			</tr>
		</thead>
	);
}

export default TableHead;
