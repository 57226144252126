import { ReactNode } from 'react';

export interface TableBodyProps {
	children: ReactNode;
}

export function TableBody(props: TableBodyProps) {
	return (
		<tbody className="relative bg-white divide-y divide-gray-200">
			{props.children}
		</tbody>
	);
}

export default TableBody;
