import {
	FieldProps,
	MiscAndAttachmentsFieldProps,
} from '@pangea-lis-apps/utils';

import V4_1 from './v4_1';
import Fallback from './fallback';
import DataComponents from '../../shared/data-components';

const ProviderInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '4.1') return <V4_1.ProviderInformation {...props} />;
		else return <Fallback.ProviderInformation {...props} />;
	}

	return <DataComponents.ProviderInformation {...props} />;
};

const OrganizationInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '4.1')
			return <V4_1.OrganizationInformation {...props} />;
		else return <Fallback.OrganizationInformation {...props} />;
	}

	return <DataComponents.OrganizationInformation {...props} />;
};

const OrderAndSpecimenInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '4.1')
			return <V4_1.OrderAndSpecimenInformation {...props} />;
		else return <Fallback.OrderAndSpecimenInformation {...props} />;
	}

	return null;
};

const PatientInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;
		if (version === '4.1') return <V4_1.PatientInformation {...props} />;
		else return <Fallback.PatientInformation {...props} />;
	}

	return null;
};

const BillingInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '4.1') return <V4_1.BillingInformation {...props} />;
		else return <Fallback.BillingInformation {...props} />;
	}

	return null;
};

const RelevantClinicalInformation = (props: FieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '4.1')
			return <V4_1.RelevantClinicalInformation {...props} />;
		else return <Fallback.RelevantClinicalInformation {...props} />;
	}

	return null;
};

const MiscAndAttachments = (props: MiscAndAttachmentsFieldProps) => {
	if (props.data.type === 'clinic') {
		const version = props.data.requisition_form.metadata.version;

		if (version === '4.1') return <V4_1.MiscAndAttachments {...props} />;
		else return <Fallback.MiscAndAttachments {...props} />;
	}

	return null;
};

export const BladderCAREComponents = {
	BillingInformation,
	PatientInformation,
	ProviderInformation,
	OrganizationInformation,
	OrderAndSpecimenInformation,
	RelevantClinicalInformation,
	MiscAndAttachments,
};

export default BladderCAREComponents;
