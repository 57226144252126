/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	useId,
	Dispatch,
	useState,
	useEffect,
	ReactNode,
	useContext,
	createContext,
	SetStateAction,
} from 'react';
import { useAuth0, User } from '@auth0/auth0-react';

import { useAxios } from '@pangea-lis-apps/ui';
import {
	Permission,
	readJsonFile,
	NavigationTab,
	NavigationItem,
	Auth0Permission,
} from '@pangea-lis-apps/utils';

export interface TabsContextType {
	allTabs: any | null;
	setRefreshTabs: Dispatch<SetStateAction<boolean>>;
	setAllTabs: Dispatch<SetStateAction<any | null>>;
}

export const TabsContext = createContext<TabsContextType>({
	allTabs: [],
	setAllTabs: () => {},
	setRefreshTabs: () => {},
});

interface TabsProviderProps {
	children: ReactNode;
}

function arraysHaveSameValues(arr1: string[], arr2: string[]): boolean {
	if (arr1.length !== arr2.length) return false;

	const sortedArr1 = arr1.slice().sort();
	const sortedArr2 = arr2.slice().sort();

	return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export function TabsProvider({ children }: TabsProviderProps) {
	const toastId = useId();
	const axios = useAxios(toastId);

	const { user, loginWithRedirect } = useAuth0();

	const apiPrefix =
		process.env['NX_PORTAL_TYPE'] === 'CUSTOMER'
			? '/api/organization-user'
			: '/api/user';

	const [refreshTabs, setRefreshTabs] = useState(false);
	const [allTabs, setAllTabs] = useState<NavigationItem[] | null>(null);

	useEffect(() => {
		let unmounted = false;

		const getStaffTabs = async (user: User) => {
			if (!axios) return;

			try {
				// Get the group id permissions from the backend
				const {
					data: { data: permissions },
				} = await (
					await axios
				).get(`/api/organization-staff/users/${user.sub}/permissions`);

				if (permissions) {
					// Return tabs based on the group id permissions of the staff member
					const organizationAssociateConfig = await readJsonFile(
						'/assets/entities/organization-associate.json'
					);

					if (!unmounted) {
						const permissionNames = permissions.map(
							(permission: Auth0Permission) =>
								permission.permission_name
						);

						return organizationAssociateConfig.tabs.filter(
							(tab: NavigationTab) =>
								permissionNames.includes(
									tab.required_permission_for_staff
								)
						);
					}
				}
			} catch (error) {
				console.log(error);
			}

			return;
		};

		/**
		 * Returns the navigation tabs for the user based on their assigned roles.
		 *
		 * In the context of organizations, this returns the navigation tabs for the associate.
		 *
		 * @returns str[]: List of all the tabs for the user based on their assigned roles.
		 */
		const getNavTabs = async (user: User) => {
			const tabs = [];
			const allRolesForUser = user['custom_claims/roles'];

			try {
				let rolesPool = extractRoles(allRolesForUser);

				const isSuperAdmin = rolesPool.includes('super-admin');

				if (isSuperAdmin) {
					const data = await readJsonFile(
						'/assets/entities/index.json'
					);

					rolesPool = data.entities;
				}

				for (const role of rolesPool) {
					const data = await readJsonFile(
						`/assets/entities/${role}.json`
					);

					tabs.push(data);
				}

				return tabs;
			} catch (error) {
				console.log(error);
			}

			return;
		};

		const fetchTabs = async (user: User) => {
			const userIsStaffMember = user['custom_claims/roles'].includes(
				'BITSS_organization-staff'
			);

			const tabs = userIsStaffMember
				? await getStaffTabs(user)
				: await getNavTabs(user);

			if (tabs) {
				setAllTabs(tabs);
			}
		};

		if (user) fetchTabs(user);

		return () => {
			unmounted = true;
		};
	}, [user, axios, toastId, refreshTabs]);

	useEffect(() => {
		let unmounted = false;

		const fetchData = async () => {
			if (!axios || !user) return;

			try {
				const {
					data: { data },
				} = await (await axios).get(`${apiPrefix}/user-info`);

				if (!unmounted) {
					const parsedData = JSON.parse(data);

					const latestRoles = parsedData.roles
						.filter((option: Permission) =>
							option.name.includes('BITSS')
						)
						.map((option: Permission) => option.name);

					const currentRoles = user['custom_claims/roles'].filter(
						(option: string) => option.includes('BITSS')
					);

					if (!arraysHaveSameValues(latestRoles, currentRoles)) {
						// Force logoout if the roles (as assigned by account manager or support account) change
						localStorage.clear();
						sessionStorage.clear();

						loginWithRedirect({
							appState: {
								returnTo: window.location.pathname,
							},
							redirectUri:
								window.location.origin.concat('/callback/'),
						});
					}
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();

		return () => {
			unmounted = true;
		};
	}, [user, axios]);

	return (
		<TabsContext.Provider
			value={{
				allTabs,
				setAllTabs,
				setRefreshTabs,
			}}
		>
			{children}
		</TabsContext.Provider>
	);
}

const extractRoles = (allRolesForUser: string[]) => {
	// Extract appropriate roles for project (e.g., for BITSS)
	const role_prefix = process.env['NX_AUTH0_ROLE_PREFIX'];

	if (!role_prefix) throw new Error('NX_AUTH0_ROLE_PREFIX undefined!');

	return allRolesForUser
		.filter((role) => role.includes(role_prefix))
		.map((role) => role.split('_')[1]);
};

export function useTabsContext() {
	return useContext(TabsContext) as TabsContextType;
}
